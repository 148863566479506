import { Select } from '@chakra-ui/react';
import moment from 'moment';

function generateBillingPeriods(yearString: string, onePerMonth: boolean) {
  const periods = [];
  const year = parseInt(yearString);

  if (year < 2024 || onePerMonth) {
    // If year is before 2024, return array with names of the months
    for (let month = 0; month < 12; month++) {
      periods.push(moment().year(year).month(month).format('MM'));
    }
  } else {
    // If year is 2024 or later, return 24 strings, 2 strings per month
    for (let month = 0; month < 12; month++) {
      periods.push('01-14. ' + moment().year(year).month(month).format('MM'));
      periods.push(
        '15-' +
          moment().year(year).month(month).endOf('month').format('DD') +
          '. ' +
          moment().year(year).month(month).format('MM'),
      );
    }
  }

  return periods;
}

export default function BillingPeriodSelector(props: {
  year: string;
  setBillingPeriod: Function;
  billingPeriod: string;
  onePerMonth?: boolean;
}) {
  const { year, setBillingPeriod, billingPeriod, onePerMonth } = props;
  const billingPeriods = generateBillingPeriods(year, onePerMonth);
  return (
    <Select
      placeholder='Chose period'
      onChange={(e) => setBillingPeriod(e.target.value)}
      value={billingPeriod}
      w='150px'
      m='5px'
      bg='white'
    >
      {billingPeriods.map((period, index) => (
        <option key={index} value={period}>
          {period}
        </option>
      ))}
    </Select>
  );
}
