import {
  Button,
  Flex,
  Text,
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Input,
  Link,
} from '@chakra-ui/react';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { TfiExchangeVertical } from 'react-icons/tfi';
import { useGetCreativesOfPackage } from '../../../api/packages';
import { useState } from 'react';
import { TbArrowsExchange2 } from 'react-icons/tb';
import { useChangeCreativeOnProject, useRemoveCreative } from '../../../api/projects';
import React from 'react';
import { MdDelete } from 'react-icons/md';
import { ProjectState } from '../constants';

type creativeType = { name: string; _id: string };

const CreativeHandler = ({ project, refetch }: { project: any; refetch: Function }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [photographer, setPhotographer] = useState<{ name: string; _id: string }>();
  const [{ data: creativesData, error: getCreativesError }, getCreatives] =
    useGetCreativesOfPackage(project.packageId);

  const [{ loading }, changeCreative] = useChangeCreativeOnProject(project._id);
  const [{}, removeCreative] = useRemoveCreative(project._id);

  const handleSelectCreative = (creative: creativeType) => {
    setPhotographer(creative);
    onOpen();
  };

  const handleChangeCreativeOnProject = async () => {
    try {
      await changeCreative({
        data: {
          photographer: photographer?._id,
        },
      });
      toast({
        title: 'New creative added to project successfully',
        status: 'success',
        duration: 3000,
      });
      refetch();
    } catch {
      toast({
        title: 'Error during adding creative to project',
        status: 'error',
        duration: 3000,
      });
    }

    refetch();
    onClose();
  };

  const handleInputChange = async (value: any) => {
    try {
      await getCreatives({ params: { name: value } });
    } catch {
      console.log(getCreativesError);
    }
  };

  const handleRemoveCreative = async () => {
    try {
      await removeCreative();

      toast({
        title: 'Creative removed from project successfully',
        status: 'success',
        duration: 3000,
      });
      refetch();
    } catch {
      toast({
        title: 'Error during removing creative from project',
        status: 'error',
        duration: 3000,
      });
    }

    onClose();
  };

  const filteredCreatives =
    creativesData &&
    creativesData.filter(
      (creative: creativeType) => creative.name !== project.photographerObj.name,
    );

  return (
    <Box>
      {[ProjectState.CREATED, ProjectState.STARTED, ProjectState.ON_HOLD].includes(project.status) && (
        <Popover>
          <PopoverTrigger>
            {project.status === ProjectState.CREATED ? (
              <Flex
                onClick={() => getCreatives()}
                cursor='pointer'
                justifyContent='center'
                ml='5px'
              >
                <IoMdAddCircleOutline size='20' />
              </Flex>
            ) : (
              <Box onClick={() => getCreatives()} w='20px' m='5px' cursor='pointer'>
                <TfiExchangeVertical size='20' />
              </Box>
            )}
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody>
              <Box minHeight='30px'>
                {project.photographerObj.name && (
                  <Flex h='40px' alignItems='center'>
                    <Text fontWeight='bold'>{project.photographerObj.name}</Text>
                    <Box ml='10px' onClick={handleRemoveCreative} cursor='pointer'>
                      <MdDelete size={20} />
                    </Box>
                  </Flex>
                )}
                <Input
                  onChange={(e) => handleInputChange(e.target.value)}
                  h='30px'
                  mb='10px'
                  placeholder='Search by name...'
                ></Input>
                {filteredCreatives && filteredCreatives.length > 0
                  ? filteredCreatives.map((creative: creativeType) => (
                      <Text
                        _hover={{ fontWeight: 'bold' }}
                        onClick={() => handleSelectCreative(creative)}
                        h='25px'
                        cursor='pointer'
                      >
                        {creative.name}
                      </Text>
                    ))
                  : `Ther's no other creative on this package`}
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent minWidth='1000px'>
          <ModalHeader>Are you sure, you want to change the creative on this project?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {photographer && (
              <Flex justifyContent='center' alignItems='center'>
                <Text fontSize='20px' m='10px' fontWeight='bold'>
                  {project.photographerObj.name ?? 'Empty spot'}
                </Text>
                <TbArrowsExchange2 size='80' color='#6b52ff' />
                <Text fontSize='20px' m='10px' fontWeight='bold'>
                  {photographer?.name}
                </Text>
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant='photonMedium' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant='photonDark'
              mr={3}
              onClick={handleChangeCreativeOnProject}
              isLoading={loading}
            >
              Change
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default CreativeHandler;
