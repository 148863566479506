import { useCallback, useEffect, useState } from 'react';
import { useGetAnnualProjects } from 'api/dashboard';
import { Box, Text, Flex } from '@chakra-ui/react';
import moment from 'moment';

import StackedColumnChart from './charts/StackedColumnChart';
import YearSelector from './YearSelector';
import CountrySelector from './CountrySelector';

export default function AnualProjects() {
  const [year, setYear] = useState(moment().format('YYYY'));
  const [country, setCountry] = useState('');
  const [{ data }, getAnualProjects] = useGetAnnualProjects();

  const callGetAnualProjects = useCallback(
    async (country: string, year: string) => {
      try {
        await getAnualProjects({
          params: { year, country },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getAnualProjects, year],
  );

  useEffect(() => {
    if (year) {
      callGetAnualProjects(country, year);
    }
  }, [callGetAnualProjects, year, country]);

  return (
    data && (
      <Box borderRadius='20px' p='10px' border='solid 1px lightgrey' mt='10px'>
        <Text fontSize='xl' m='10px'>
          Finished and cancelled projects per year
        </Text>
        <Flex>
          <YearSelector year={year} setYear={setYear} />
          <CountrySelector country={country} setCountry={setCountry} />
        </Flex>

        {Object.keys(data).length > 0 && (
          <Flex m='10px' justifyContent={'space-around'}>
            <StackedColumnChart
              labels={['Finished', 'Cancelled']}
              width='700px'
              axisLabels={Object.keys(data)}
              unit={'db'}
              numbers={[
                (Object.values(data) as any).map((item: any) => item.finished),
                (Object.values(data) as any).map((item: any) => item.canceled),
              ]}
            />
          </Flex>
        )}
      </Box>
    )
  );
}
