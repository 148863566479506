import { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Spinner, Switch, Text } from '@chakra-ui/react';
import { useGetProjects } from 'api/projects';
import { ProjectListFilterTypes } from './types';
import { useLocation } from 'react-router-dom';
import ProjectListFilters from './components/ProjectListFilters';
import Card from 'components/card/Card';
import Calendar from './components/Calendar';
import moment from 'moment';
import Bugsnag from "@bugsnag/js";


export default function ProjectCalendar() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { creative = '', client = '', city = '' } = Object.fromEntries(queryParams.entries());

  let statuses = Object.fromEntries(queryParams.entries()).statuses || ([] as any);
  if (!Array.isArray(statuses)) {
    statuses = [statuses];
  }
  let country = Object.fromEntries(queryParams.entries()).country || ([] as any);
  if (!Array.isArray(country)) {
    country = country.split(',');
  }

  const from =
    moment(Object.fromEntries(queryParams.entries()).date).startOf('month') ||
    moment().startOf('week');
  const to =
    moment(Object.fromEntries(queryParams.entries()).date).add(1, 'month') ||
    moment().endOf('week');

  const [filters, setFilters] = useState<ProjectListFilterTypes>({
    creative,
    client,
    statuses,
    city,
    country,
    from,
    to,
  });
  const [showFilters, setShowFilters] = useState(true);

  const [
    { data: projectsData, loading: getProjectsLoading, error: getProjectsError },
    getProjects,
  ] = useGetProjects();

  const callGetProjects = useCallback(async (filters: ProjectListFilterTypes) => {
    if (filters) {
      try {
        await getProjects({
          params: {
            filters: {
              ...filters,
              from: filters.from.format('YYYY.MM.DD'),
              to: filters.to.format('YYYY.MM.DD'),
            },
            calendar: true,
          },
        });
      } catch (e) {
        Bugsnag.notify(e as Error);
      }
    }
  }, []);

  useEffect(() => {
    callGetProjects(filters);
  }, []);

  const refetchData = async (updatedFilters: ProjectListFilterTypes) => {
    if (updatedFilters.from || filters.from) {
      await callGetProjects(updatedFilters ?? filters);
    }
  };

  return (
    <Card mt='100px'>
      <Box>
        <Text fontSize='22px' fontWeight='700'>
          Calendar
        </Text>
        <Text fontSize='18px'>On this page, all times are displayed in your current time zone</Text>
        <Flex mt='30px' alignItems='center'>
          <Text m='10px 20px'>Show filters</Text>
          <Switch
            ml='10px'
            isChecked={showFilters}
            colorScheme='brandScheme'
            size='md'
            onChange={() => setShowFilters((prevShowFilters) => !prevShowFilters)}
          />
        </Flex>
        <ProjectListFilters
          filters={filters}
          callGetProjects={refetchData}
          setFilters={setFilters}
          Buttons={() => {}}
          showFilters={showFilters}
        />
        {
          projectsData === undefined
          || getProjectsLoading
          || getProjectsError
            ? <Flex justifyContent='center' m='50px' bg='transparent'>
              <Spinner />
            </Flex>
            : <Calendar
              projectsData={projectsData.data}
              refetchData={refetchData}
              filters={filters}
              setFilters={setFilters}
            />
        }
      </Box>
    </Card>
  );
}
