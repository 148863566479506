import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { components } from 'chakra-react-select';

export const statusColors: { [key: string]: { primary: string; secondary: string } } = {
  created: { primary: '#f2f2f2', secondary: 'black' },
  started: { primary: '#fae38c', secondary: 'black' },
  'on-hold': { primary: '#fae38c', secondary: 'black' },
  'in-progress': { primary: '#fdea8c', secondary: 'black' },
  'photographer-editing': { primary: '#ffc942', secondary: 'black' },
  'ai-post-production': { primary: '#a6a3ff', secondary: 'black' },
  'editor-post-production': { primary: '#a6a3ff', secondary: 'black' },
  delivered: { primary: '#6b52ff', secondary: 'white' },
  'complain-in-progress': { primary: '#ea4b00', secondary: 'black' },
  finished: { primary: '#26DC6E', secondary: 'black' },
  canceled: { primary: '#4a4a4a', secondary: 'white' },
  deleted: { primary: '#031c21', secondary: 'white' },
};

export const CustomFieldStyle = {
  alignItems: 'center',
  bg: 'white',
  borderRadius: '15px',
  p: '5px',
  pl: '15px',
  h: '2.5rem',
  mb: 4,
  w: { base: '100%', lg: '400px' },
};

export const dot = (color = 'transparent') => ({
  padding: 0,
  ':before': {
    backgroundColor: color,
    borderRadius: 50,
    content: '" "',
    marginRight: 1,
    marginLeft: 0,
    height: 3,
    width: 3,
  },
});

export const DatePickerStyles = {
  inputProps: {
    border: 'solid #A3AED0 1px',
    py: '11px',
    borderRadius: '5px',
    _placeholder: { color: 'black' },
    width: '300px',
  },
};

export const chakraStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '300px',
    borderColor: '#A3AED0',
    bg: 'white',
    zIndex: 3,
    borderRadius: '5px',
  }),
  multiValue: (provided: any, { data }: { data: any }) => ({
    ...provided,
    backgroundColor: 'white',
    fontSize: '14px',
    height: '20px',
    ...dot(statusColors[data.value].primary),
  }),
  multiValueLabel: (provided: any, { data }: { data: any }) => ({
    ...provided,
    color: 'black',
  }),
  multiValueRemove: (provided: any, { data }: { data: any }) => ({
    ...provided,
    color: 'black',
    margin: 0,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'black',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: 'grey',
    '&:hover': {
      bg: 'white',
      color: 'black',
    },
  }),
};

export const countrySelectorStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '300px',
    borderColor: '#A3AED0',
    bg: 'white',
    zIndex: 4,
    borderRadius: '5px',
    p: '0px',
  }),
  multiValue: (provided: any, { data }: { data: any }) => ({
    ...provided,
    backgroundColor: 'white',
    fontSize: '15px',
    height: '20px',
    mt: '4px',
    border: 'solid lightgrey 2px',
  }),
  multiValueLabel: (provided: any, { data }: { data: any }) => ({
    ...provided,
    color: 'black',
  }),
  multiValueRemove: (provided: any, { data }: { data: any }) => ({
    ...provided,
    color: 'black',
    mr: 0,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'black',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: 'grey',
    '&:hover': {
      bg: 'white',
      color: 'black',
    },
  }),
};

export const Option = (props: any) => {
  const { value } = props.data;

  return (
    <components.Option {...props}>
     <Box display='flex' alignItems='center'>
        <Box
          width='11px'
          height='11px'
          marginRight='8px'
          backgroundColor={statusColors[value]?.primary || 'black'}
          borderRadius='50px'
        ></Box>
        {props.label}
      </Box>
    </components.Option>
  );
};

export const StyleWrapper = styled.div`
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    padding: 0px 20px;
  }

  .fc-button {
    border: none;
    box-shadow: none;
  }

  .fc-button.fc-prev-button,
  .fc-button.fc-next-button {
    background-color: white;
    color: black;
    border: solid black 1px;
    border-radius: 20px;
    transition: background-color 0.3s ease;
  }

  div.fc button.fc-button.fc-prev-button:focus,
  div.fc button.fc-button.fc-next-button:focus,
  div.fc button.fc-button.fc-prev-button:active,
  div.fc button.fc-button.fc-next-button:active {
    outline: none;
    box-shadow: none;
  }

  .fc-timeGridWeek-button.fc-button.fc-button-primary:focus,
  .fc-dayGridMonth-button.fc-button.fc-button-primary:focus,
  .fc-timeGridDay-button.fc-button.fc-button-primary:focus {
    box-shadow: none;
  }

  .fc-timeGridWeek-button.fc-button.fc-button-primary,
  .fc-dayGridMonth-button.fc-button.fc-button-primary,
  .fc-timeGridDay-button.fc-button.fc-button-primary {
    background-color: #a6a3ff;
  }

  .fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active,
  .fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active,
  .fc-timeGridDay-button.fc-button.fc-button-primary.fc-button-active {
    background-color: #6b52ff;
  }

  .fc-timeGridDay-button.fc-button.fc-button-primary.fc-button-active,
  .fc-timeGridDay-button.fc-button.fc-button-primary {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active,
  .fc-dayGridMonth-button.fc-button.fc-button-primary {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .fc-event {
    border-radius: 20px;
    padding: 0px 8px;
    overflow: hidden;
  }

  .fc-more-popover {
    background-color: white;
    z-index: 2;
  }
`;
