import React from 'react';

import { useDisclosure, Button } from '@chakra-ui/react';
import CreateTemplateModal from './CreateTemplateModal';

export default function TemplateListMenu(props: { [x: string]: any }) {
  const { onRefresh } = props;

  const {
    isOpen: isOpenCreateModal,
    onOpen: onOpenCreateModal,
    onClose: onCloseCreateModal,
  } = useDisclosure();

  return (
    <>
      <Button variant='photonLight' w='100px' onClick={() => onOpenCreateModal()}>
        Add
      </Button>
      <CreateTemplateModal
        isOpen={isOpenCreateModal}
        onClose={(success: any) => {
          onCloseCreateModal();
          success && onRefresh();
        }}
        toCreate = {true}
      />
    </>
  );
}
