import React, { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stat,
  StatLabel,
  StatNumber,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  HeaderGroup,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import {
  BiSolidDownArrowAlt,
  BiSolidUpArrowAlt,
} from 'react-icons/bi';
import Card from '../../../components/card/Card';
import { GrLinkPrevious, GrLinkNext } from 'react-icons/gr';
import { ColumnType, ProjectListFilterTypes, Row } from '../types';
import ProjectListFilters from './ProjectListFilters';
import {
  NavLink,
  useSearchParams,
} from 'react-router-dom';
import moment from 'moment-timezone';
import { AiFillStar } from 'react-icons/ai';
import { useGetProjectsCSV, useUpdateProject } from '../../../api/projects';
import CreativeHandler from './CreativeHandler';
import EditorHandler from './EditorHandler';
import CancelProjectButton from './CancelProjectButton';
import { downloadFile } from '../../../utils/download-file';
import { Spinner } from '@chakra-ui/react';
import { MdOutlineDone } from 'react-icons/md';
import { currencyFormatter } from '../../../utils/number-formatter';
import { statusColors } from '../variables/styles';
import config from '../../../config';
import { getLocalTime } from '../../../utils/local-time-formatter';
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import Bugsnag from "@bugsnag/js";
import { getCountryName } from 'utils/country-codes';

const defaultColumns = [
  'adminFlag',
  'projectName',
  'clientObj',
  'shootingTime',
  'shootingTimeLocal',
  'status',
  'locationObj.country',
  'locationObj.city',
  'photographerObj',
  'editorObj',
  'creativePrice',
  'photographerReview.rating',
  'actions',
];

const sendOutColumns = [
  'adminFlag',
  'projectName',
  'shootingTimeLocal',
  'locationObj.city',
  'creativePrice',
  'actions',
];

const checkNewColumn = (columnsData: ColumnType[], storedColumns: ColumnType[]): ColumnType[] => {
  const updatedColumns = [];

  for (const columnData of columnsData) {
    const storedColumn = storedColumns.find((sc) => sc.accessor === columnData.accessor);
    updatedColumns.push(storedColumn || columnData);
  }

  return updatedColumns;
};

export default function ProjectListTable(props: {
  columnsData: ColumnType[];
  tableData: any;
  total: number;
  page: number;
  perPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  callGetProjects: Function;
  filters: ProjectListFilterTypes;
  setFilters: React.Dispatch<React.SetStateAction<ProjectListFilterTypes>>;
  setSorting: React.Dispatch<React.SetStateAction<{ field: string; order: number }>>;
  sorting: { field: string; order: number };
}) {
  const {
    columnsData,
    tableData,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    callGetProjects,
    setFilters,
    filters,
    setSorting,
    sorting,
  } = props;

  const [showFilters, setShowFilters] = useState(true);
  const [actualProject, setActualProject] = useState<any>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [storedColumns, setStoredColumns] = useLocalStorage('projectListColumns', []);
  const [displayColumns, setDisplayColumns] = useState(checkNewColumn(columnsData, storedColumns));
  const [creativePriceSums, setCreativePriceSums] = useState({});

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const data = useMemo(() => tableData || [], [tableData]);
  const columns = useMemo(() => displayColumns, [displayColumns]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const pageSizes = [1, 5, 10, 25, 50, 100];
  const startRow = total !== 0 ? (page - 1) * perPage + 1 : 0;
  const endRow = Math.min(page * perPage, total);
  const actualEndRow = Math.min(endRow, total);

  const [, updateProject] = useUpdateProject(actualProject._id);
  const [{ loading: exportProjectsLoading }, exportProjects] = useGetProjectsCSV();

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page: pageData,
    prepareRow,
    setPageSize,
    state: { pageSize },
  } = tableInstance;

  useEffect(() => {
    setPageSize(perPage);
  }, [perPage]);

  const handleAdminFlagChange = async (value: boolean, project: any) => {
    setActualProject(project);
    try {
      await updateProject({ data: { adminFlag: value } });
      toast({
        title: 'Project updated',
        status: 'success',
        duration: 3000,
      });
      await refetchTableData(filters);
    } catch {
      toast({
        title: 'Could not update project flag',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const handlePageSizeChange = (e: number) => {
    setPage(1);
    setPerPage(e);
  };

  useEffect(() => {
    setSearchParams((params) => {
      params.set('page', page.toString());
      return params;
    });
  }, [page]);

  useEffect(() => {
    setPageSize(perPage);
    setSearchParams((params) => {
      params.set('perPage', perPage.toString());
      return params;
    });
  }, [perPage]);

  useEffect(() => {setStoredColumns(displayColumns)}, [displayColumns]);

  useEffect(() => {
    const viewMode = searchParams.get('viewMode');
    if (viewMode === 'sendOut') {
      getCreativePriceSumForSendOutView();
    } else {
      setCreativePriceSums({});
    }
  }, [searchParams]);

  const refetchTableData = async (updatedFilters: ProjectListFilterTypes) => {
    await callGetProjects(page, pageSize, sorting, updatedFilters ?? filters);
  };

  const handleExportProjects = async () => {
    let result;
    try {
      result = await exportProjects({
        params: {filters},
        responseType: 'arraybuffer',
      });
    } catch (e) {
      Bugsnag.notify(e as Error);
    } finally {
      if (result && result.data) {
        downloadFile(
          result.data,
          `certificate_of_completion${moment().format('DD_MMMM_hh_mm')}.csv`,
          'application/octet-stream',
        );
      }
    }
  };

  const handleSort = (column: any) => {
    let columnId = column.id;
    const clickedColumn = headerGroups
      .flatMap((group) => group.headers)
      .find((column) => column.id === columnId);

    if (clickedColumn && column.sorting) {
      const isColumnAlreadySorted = sorting.field === columnId;
      const order = isColumnAlreadySorted ? sorting.order * -1 : -1;

      setSearchParams((params) => {
        params.set('sort', columnId);
        params.set('order', order as unknown as string);
        return params;
      });
      setPage(1);
      setSorting({ field: columnId, order: order });
    }
  };

  const getCreativePriceSumForSendOutView = async () => {
    let sumObj: { [key: string]: number } = {};

    await tableData.forEach((p: { currency: string; creativePrice: number }) => {
      if (!sumObj[p.currency]) {
        sumObj[p.currency] = p.creativePrice;
      } else {
        sumObj[p.currency] += p.creativePrice;
      }
    });
    setCreativePriceSums(sumObj);
  };

  const setColumnVisibility = (change: string) => {
    let updatedColumns;
    if (change === 'default') {
      updatedColumns = columns.map((column: ColumnType) => ({
        ...column,
        show: defaultColumns.includes(column.accessor),
      }));
    } else if (change === 'sendOut') {
      updatedColumns = columns.map((column: ColumnType) => ({
        ...column,
        show: sendOutColumns.includes(column.accessor),
      }));
    } else {
      updatedColumns = change
        ? displayColumns.map((column: ColumnType) =>
            column.accessor === change ? { ...column, show: !column.show } : column,
          )
        : columnsData;
    }
    setDisplayColumns(updatedColumns);
  };
  const ColumnAndExportButtons = () => {
    return (
      <Grid
        templateColumns={{ base: '1fr 1fr', xl: '1fr 1fr 1fr 1fr' }}
        gap='0px'
        w={{ base: '320px', xl: '600px' }}
        mr={{ base: '20px' }}
        position='absolute'
        right={{ xl: '40px' }}
        mt={{ base: showFilters ? '190px' : '100px', xl: '10px' }}
      >
        <Button ml='0px' variant='photonLight' w='130px' onClick={onOpen}>
          Customize
        </Button>
        <Button
          ml='0px'
          variant='photonLight'
          w='130px'
          onClick={() => setColumnVisibility('sendOut')}
        >
          Send out
        </Button>
        <Button
          ml='0px'
          variant='photonLight'
          w='130px'
          onClick={() => setColumnVisibility('default')}
        >
          Default
        </Button>
        <Button
          w='130px'
          m='10px'
          ml='0px'
          isDisabled={exportProjectsLoading}
          variant='photonMedium'
          onClick={() => handleExportProjects()}
        >
          {exportProjectsLoading ? <Spinner /> : 'Export'}
        </Button>
      </Grid>
    );
  };

  return (
    <>
      <Card flexDirection='column' w='100%' px='0px'>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
          <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
            Projects
          </Text>
        </Flex>
        <Flex justifyContent='space-between' p='0px 0px' m='0px 40px'>
          <Flex pt='30px' alignItems='center'>
            <Text marginTop='auto'>Show filters</Text>
            <Switch
              ml='10px'
              isChecked={showFilters}
              colorScheme='brandScheme'
              size='md'
              onChange={() => setShowFilters((prevShowFilters) => !prevShowFilters)}
            />
          </Flex>
        </Flex>

        <Box p='20px'>
          <ProjectListFilters
            filters={filters}
            callGetProjects={refetchTableData}
            setFilters={setFilters}
            Buttons={ColumnAndExportButtons}
            showFilters={showFilters}
            isListFilter={true}
            setPage={setPage}
          />
        </Box>

        {total > 0 ? (
          <Box overflowX='scroll' m='0px 20px'>
            <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
              <Thead>
                {headerGroups.map((headerGroup: HeaderGroup, index) => (
                  <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column: any, index: number) => {
                      return (
                        column.show && (
                          <Th
                            pe='10px'
                            key={index}
                            borderColor={borderColor}
                            textAlign='center'
                            onClick={() => handleSort(column)}
                          >
                            <Flex
                              justify='space-between'
                              fontSize={{ sm: '10px', lg: '12px' }}
                              color='gray.400'
                              justifyContent='center'
                              alignItems='center'
                            >
                              {column.render('Header')}
                              {column.sorting && (
                                <Flex direction='column'>
                                  {sorting.field === column.id && sorting.order > 0 ? (
                                    <BiSolidUpArrowAlt size='20px' color='#6B52FFB3' />
                                  ) : (
                                    <BiSolidUpArrowAlt opacity={0.5} size='20px' />
                                  )}
                                  {sorting.field === column.id && sorting.order < 0 ? (
                                    <BiSolidDownArrowAlt size='20px' color='#6B52FFB3' />
                                  ) : (
                                    <BiSolidDownArrowAlt opacity={0.5} size='20px' />
                                  )}
                                </Flex>
                              )}
                            </Flex>
                          </Th>
                        )
                      );
                    })}
                  </Tr>
                ))}
              </Thead>

              <Tbody {...getTableBodyProps()}>
                {pageData.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr
                      {...row.getRowProps()}
                      key={index}
                      onMouseEnter={() => setActualProject(row.original)}
                    >
                      {row.cells.map((cell: any, index) => {
                        const currentRow: Row = cell.row;
                        let data;
                        if (cell.column.show) {
                          data = (
                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                              {cell.value}
                            </Text>
                          );
                          if (cell.column.id === 'status') {
                            data = (
                              <Flex justifyContent='center'>
                                <Box
                                  bg={statusColors[cell.value].primary}
                                  borderRadius='20px'
                                  p='5px 10px'
                                  color={statusColors[cell.value].secondary}
                                  fontWeight='bold'
                                  textAlign='center'
                                >
                                  <Text>{cell.value}</Text>
                                </Box>
                              </Flex>
                            );
                          }

                          if (cell.column.id === 'projectName') {
                            data = (
                              <Flex justifyContent='start' w='100%'>
                                <Link
                                  as={NavLink}
                                  to={`/intel/project/${currentRow.original._id}`}
                                  textDecoration='none'
                                >
                                  <Text fontWeight='bold' color='black' textAlign='left'>
                                    {cell.value}
                                  </Text>
                                </Link>
                              </Flex>
                            );
                          }
                          if (cell.column.id === 'clientObj') {
                            data = (
                              <Link
                                textDecoration='none'
                                fontWeight='bold'
                                color='black'
                                href={`${config.oldAdminUrl}/#/enterprise-clients/${cell.value.id}/show`}
                                target='_blank'
                              >
                                {cell.value.name}
                              </Link>
                            );
                          }
                          if (cell.column.id === 'photographerObj') {
                            data = (
                              <Flex justifyContent='center' alignItems='center' direction='column'>
                                <Link as={NavLink} to={`/intel/creative/${cell.value.id}`}>
                                  <Text align='center' fontWeight='bold' color='black'>
                                    {cell.value?.name}
                                  </Text>
                                </Link>

                                <CreativeHandler
                                  project={row.original}
                                  refetch={refetchTableData}
                                />
                              </Flex>
                            );
                          }
                          if (cell.column.id === 'editorObj') {
                            data = (
                              <Flex justifyContent='center' alignItems='center' direction='column'>
                                <Text align='center' fontWeight='bold' color='black'>
                                  {cell.value?.name}
                                </Text>
                                <EditorHandler project={row.original} refetch={refetchTableData} />
                              </Flex>
                            );
                          }
                          if (cell.column.id === 'adminFlag') {
                            data = (
                              <Button
                                variant='no-hover'
                                cursor='pointer'
                                bg='white'
                                onClick={() => {
                                  handleAdminFlagChange(
                                    !cell.value,
                                    currentRow.original,
                                  );
                                }}
                              >
                                <AiFillStar
                                  color={cell.value ? '#6b52ff' : 'lightgrey'}
                                  size='25'
                                />
                              </Button>
                            );
                          }
                          if (cell.column.id === 'locationObj.country') {
                              data = (
                                <Text color={textColor} fontSize='sm' fontWeight='700'>
                                  {getCountryName(cell.value)}
                                </Text>
                              );
                          }
                          if (
                            ['clientPrice', 'creativePrice', 'editorPrice'].includes(cell.column.id)
                          ) {
                            data = (
                              <Flex justifyContent='center'>
                                <Text me='10px' color={textColor} fontWeight='700' align='center'>
                                  {currentRow.original.currency === 'HUF'
                                    ? `${currencyFormatter(
                                        cell.value,
                                        currentRow.original.currency,
                                      )} Ft`
                                    : `€ ${currencyFormatter(
                                        cell.value,
                                        currentRow.original.currency,
                                      )}`}
                                </Text>
                              </Flex>
                            );
                          }
                          if (['deliveryDeadlineLocal'].includes(cell.column.id)) {
                            data = (
                              <Flex justifyContent='center'>
                                <Text me='10px' color={textColor} fontWeight='700'>
                                  {getLocalTime(
                                    currentRow.original.ianaCode,
                                    currentRow.original.deliveryDeadline,
                                  )}
                                </Text>
                              </Flex>
                            );
                          }
                          if (['shootingTimeLocal'].includes(cell.column.id)) {
                            data = (
                              <Flex justifyContent='center'>
                                <Text me='10px' color={textColor} fontWeight='700'>
                                  {getLocalTime(
                                    currentRow.original.ianaCode,
                                    currentRow.original.shootingTime,
                                  )}
                                </Text>
                              </Flex>
                            );
                          }
                          if (
                            ['shootingTime', 'deliveryDeadline', 'createdAt'].includes(
                              cell.column.id,
                            )
                          ) {
                            data = (
                              <Flex justifyContent='center'>
                                <Text me='10px' color={textColor} fontWeight='700'>
                                  {moment(cell.value).format('YYYY.MM.DD.  HH:mm')}
                                </Text>
                              </Flex>
                            );
                          } else if (cell.column.id === 'actions') {
                            data = (
                              <Flex
                                alignItems='center'
                                justifyContent='space-between'
                                width='130px'
                              >
                                <CancelProjectButton
                                  project={currentRow.original}
                                  refetchTableData={refetchTableData}
                                />
                                <Link
                                  as={NavLink}
                                  textDecoration='none'
                                  to={`/intel/project/${currentRow.original._id}`}
                                >
                                  <Button w='80px' variant='photonLight' m='0px'>
                                    View
                                  </Button>
                                </Link>
                              </Flex>
                            );
                          } else if (cell.column.id === 'fileUploaded') {
                            data = cell.value && (
                              <Flex justifyContent='center'>
                                <MdOutlineDone size={30} color='black' />
                              </Flex>
                            );
                          }
                        } else {
                          return;
                        }
                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={index}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderColor='transparent'
                          >
                            <Flex justifyContent='center'>{data}</Flex>
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        ) : (
          <Text align='center'>There is no data to display</Text>
        )}
        <Flex justify='space-between' m='10px' px='10px'>
          <Flex>
            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              variant='photonMedium'
              w='75px'
            >
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
          </Flex>
          <Flex>
            <Button onClick={() => setPage(page - 1)} bg='white' isDisabled={!page || page === 1}>
              <GrLinkPrevious />
            </Button>
            <Flex justify='center' mx='10px' mt='6px'>
              <Text color='gray.400'>
                {startRow}-{actualEndRow} of {total}
              </Text>
            </Flex>
            <Button
              onClick={() => setPage(page + 1)}
              bg='white'
              isDisabled={!page || endRow === total}
            >
              <GrLinkNext />
            </Button>
          </Flex>
        </Flex>
      </Card>
      <Flex fontWeight='bold' h='50px' w='100%' alignItems='center' mt='30px' mb='10px'>
        {Object.entries(creativePriceSums).map(
          ([currency, value]: [string, any], index: number) => {
            return (
              <Stat h='80px' key={index}>
                <StatLabel>{`Creative price summary (${currency})`}</StatLabel>
                <StatNumber>
                  {`${currencyFormatter(value, currency)}`}
                  <Text variant='stat'>{currency === 'HUF' ? 'Ft' : '€'}</Text>
                </StatNumber>
              </Stat>
            );
          },
        )}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent minWidth='1000px'>
          <ModalHeader>Chose columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns='repeat(3, 1fr)' gap={4}>
              {displayColumns
                .filter((c: any) => !['actions', 'adminFlag'].includes(c.accessor))
                .map((column: any, i: number) => {
                  return (
                    <Flex key={i} w='250px' justifyContent='end'>
                      <Text mr='10px'>{column.Header} </Text>
                      <Switch
                        isChecked={column.show}
                        colorScheme='brandScheme'
                        size='md'
                        onChange={() => setColumnVisibility(column.accessor)}
                      />
                    </Flex>
                  );
                })}
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button variant='photonDark' mr={3} onClick={onClose}>
              Set
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
