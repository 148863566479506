// Chakra imports
import { Flex, useColorModeValue, Image } from '@chakra-ui/react';

export function SidebarBrand() {

	return (
		<Flex alignItems='center' flexDirection='column'>
			<Image  src="/photon_logo_white.png" alt="Logo" />
		</Flex>
	);
}

export default SidebarBrand;
