export const columnsDataProjects = [
  {
    Header: '',
    accessor: 'adminFlag',
    show: true,
  },
  {
    Header: 'Project ID',
    accessor: '_id',
    show: false,
  },
  {
    Header: 'Project name',
    accessor: 'projectName',
    show: true,
    sorting: true,
  },
  {
    Header: 'Client',
    accessor: 'clientObj',
    show: true,
  },
  {
    Header: 'Creation date',
    accessor: 'createdAt',
    show: false,
    sorting: true,
  },
  {
    Header: 'Shooting date (local)',
    accessor: 'shootingTimeLocal',
    show: true,
    sorting: true,
  },
  {
    Header: 'Shooting date (HQ)',
    accessor: 'shootingTime',
    show: true,
    sorting: true,
  },
  {
    Header: 'Delivery deadline (local)',
    accessor: 'deliveryDeadlineLocal',
    show: false,
    sorting: true,
  },
  {
    Header: 'Delivery deadline (HQ)',
    accessor: 'deliveryDeadline',
    show: false,
    sorting: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    show: true,
  },
  {
    Header: 'Country',
    accessor: 'locationObj.country',
    show: true,
  },
  {
    Header: 'City',
    accessor: 'locationObj.city',
    show: true,
  },
  {
    Header: 'Expected number of images',
    accessor: 'expectedPhotoNumber',
    show: false,
  },
  {
    Header: 'Photographer',
    accessor: 'photographerObj',
    show: true,
  },
  {
    Header: 'Editor',
    accessor: 'editorObj',
    show: true,
  },
  {
    Header: 'Client price',
    accessor: 'clientPrice',
    show: false,
  },
  {
    Header: 'Photographer price',
    accessor: 'creativePrice',
    show: true,
  },
  {
    Header: 'Editor Price',
    accessor: 'editorPrice',
    show: false,
  },
  {
    Header: 'Rating',
    accessor: 'photographerReview.rating',
    show: true,
  },
  {
    Header: 'File uploaded',
    accessor: 'fileUploaded',
    show: false,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    show: true,
  },
];
