import useAxios from './basic/use-axios';

export const useGetFinishedUnderFiveStarRatingProjects = () =>
  useAxios(
    {
      url: '/admin/project/finished-list',
      method: 'GET',
    },
    { manual: true },
  );

export const useGetProjects = () =>
  useAxios(
    {
      url: '/admin/project/list',
      method: 'GET',
    },
    { manual: true },
  );

export const useUpdateProject = (projectId: string) =>
  useAxios(
    {
      url: `/admin/project/${projectId}`,
      method: 'PATCH',
    },
    { manual: true },
  );

export const useSendAdminReview = (projectId: string) =>
  useAxios(
    {
      url: `admin/project/${projectId}/admin-review`,
      method: 'POST',
    },
    { manual: true },
  );

export const useChangeCreativeOnProject = (projectId: string) =>
  useAxios(
    {
      url: `/admin/project/${projectId}/update-creative`,
      method: 'POST',
    },
    { manual: true },
  );

export const useChangeEditorOnProject = (projectId: string) =>
  useAxios(
    {
      url: `/admin/project/${projectId}/update-editor`,
      method: 'POST',
    },
    { manual: true },
  );

export const useCancelProject = (projectId: string) =>
  useAxios(
    {
      url: `/admin/project/${projectId}/cancel`,
      method: 'POST',
    },
    { manual: true },
  );

export const useCancelProjectByCreative = (projectId: string) =>
  useAxios(
    {
      url: `/admin/project/${projectId}/cancel-by-creative`,
      method: 'POST',
    },
    { manual: true },
  );

export const useGetProjectsCSV = () =>
  useAxios(
    {
      url: '/admin/project/csv',
      method: 'GET',
    },
    { manual: true },
  );

export const useGetProject = (projectId: string) =>
  useAxios({
    url: `/admin/project/${projectId}`,
    method: 'GET',
  });

export const useUpdateProjectPrices = (projectId: string) =>
  useAxios(
    {
      url: `/admin/project/${projectId}/update-price`,
      method: 'POST',
    },
    { manual: true },
  );

export const useAddItemsToProjectPrices = (projectId: string) =>
  useAxios(
    {
      url: `/admin/project/${projectId}/additional-item`,
      method: 'POST',
    },
    { manual: true },
  );
export const useUpdateProjectLateFeeCalculation = (projectId: string) =>
  useAxios(
    {
      url: `/admin/project/${projectId}/manage-late-fee`,
      method: 'POST',
    },
    { manual: true },
  );

export const useRemoveAdditionalItemFromProjectPrices = (projectId: string) =>
  useAxios(
    {
      url: `/admin/project/${projectId}/additional-item`,
      method: 'DELETE',
    },
    { manual: true },
  );

export const useRemoveCreative = (projectId: string) =>
  useAxios(
    {
      url: `/admin/project/${projectId}/remove-creative`,
      method: 'POST',
    },
    { manual: true },
  );

export const useGetProjectCanceller = (projectId: string) =>
  useAxios(
    {
      url: `/admin/project/${projectId}/canceller-name`,
      method: 'GET',
    },
    { manual: true },
  );
