import { Box } from '@chakra-ui/react';
import { components } from 'chakra-react-select';
import { statusColors } from '../constants';



export const dot = (color = 'transparent') => ({
  padding: 0,
  ':before': {
    backgroundColor: color,
    borderRadius: 50,
    content: '" "',
    marginRight: 1,
    marginLeft: 0,
    height: 3,
    width: 3,
  },
});

export const chakraStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '300px',
    borderColor: '#A3AED0',
    bg: 'white',
    zIndex: 3,
    borderRadius: '5px',
  }),
  multiValue: (provided: any, { data }: { data: any }) => ({
    ...provided,
    backgroundColor: 'white',
    fontSize: '14px',
    height: '20px',
    ...dot(statusColors[data.value].primary),
  }),
  multiValueLabel: (provided: any, { data }: { data: any }) => ({
    ...provided,
    color: 'black',
  }),
  multiValueRemove: (provided: any, { data }: { data: any }) => ({
    ...provided,
    color: 'black',
    margin: 0,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'black',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: 'grey',
    '&:hover': {
      bg: 'white',
      color: 'black',
    },
  }),
};



export const Option = (props: any) => {
  const { value } = props.data;

  return (
    <components.Option {...props}>
     <Box display='flex' alignItems='center'>
        <Box
          width='11px'
          height='11px'
          marginRight='8px'
          backgroundColor={statusColors[value]?.primary || 'black'}
          borderRadius='50px'
        ></Box>
        {props.label}
      </Box>
    </components.Option>
  );
};


