import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { z } from 'zod';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { GroupBase, OptionBase, Select } from 'chakra-react-select';

import {
  CreateUser,
  UpdateUser,
  useCreateAdminUsers,
  useUpdateAdminUsers,
} from '../../api/adminUsers';
import { adminRoles } from '../../variables/roles';

export const defaultForm = {
  _id: '',
  name: '',
  email: '',
  phone: '',
  password: '',
  passwordAgain: '',
  roles: [] as SelectSchema[],
};

const validators = {
  email: z.string().email('Invalid email address'),
  name: z
    .string()
    .min(3, 'Must be at least 3 characters')
    .max(255, 'Must be less than 255 characters'),
  phone: z
    .string()
    .refine((phoneNumber) => isValidPhoneNumber(phoneNumber), 'Not a valid phone number.'),
  password: z
    .string()
    .optional()
    .refine(
      (pw) =>
        !pw ||
        /(?=.*?\d)(?=.*?[!\\@#$%^&*()[\]{}\-_+=~|:;"'<>,./?])(?=.*?[a-z])(?=.*?[A-Z])(?=.{8,})/.test(
          pw,
        ),
      'Password should be at least 8 characters and should contains at least one number, spec chars and Uppercase letters',
    ),
  roles: z.array(z.any()).min(1, 'Choose a role!'),
};

const formValidator = z.object({
  email: validators.email,
  name: validators.name,
  phone: validators.phone,
  password: validators.password,
  roles: validators.roles,
});

export interface SelectSchema extends OptionBase {
  label: string;
  value: string;
}

export const roleOptions = Object.entries(adminRoles).map(([_, { key, label }]) => ({
  label,
  value: key,
}));

export const roleKeyToLabelMap = new Map<string, string>(
  Object.entries(adminRoles).map(([_, { key, label }]) => [key, label]),
);

export default function UpdateOrCreateAdminUserModal({
  selectedAdminDoc,
  isOpen,
  onClose,
  toCreate,
}: {
  isOpen: boolean;
  onClose: (successfulUpdate?: boolean) => void;
  selectedAdminDoc?: {
    _id: string;
    email: string;
    name: string;
    roles: SelectSchema[];
    password?: string;
    phone?: string;
  };
  toCreate?: boolean;
}) {
  const toast = useToast();
  const [formData, setFormData] = useState({
    passwordAgain: '',
    name: selectedAdminDoc?.name || defaultForm.name,
    email: selectedAdminDoc?.email || defaultForm.email,
    password: selectedAdminDoc?.password || defaultForm.password,
    _id: selectedAdminDoc?._id || defaultForm._id,
    phone: selectedAdminDoc?.phone || defaultForm.phone,
    roles: selectedAdminDoc?.roles || defaultForm.roles,
  });
  const [errors, setErrors] = useState('');

  const [{ loading: isUpdateInProgress, error }, updateAdminUser] = useUpdateAdminUsers();
  const [{ loading: isCreateInProgress, error: createErrors }, createAdminUser] =
    useCreateAdminUsers();

  useEffect(() => {
    if (selectedAdminDoc) {
      setFormData({
        passwordAgain: '',
        name: selectedAdminDoc?.name || defaultForm.name,
        email: selectedAdminDoc?.email || defaultForm.email,
        password: selectedAdminDoc?.password || defaultForm.password,
        _id: selectedAdminDoc?._id || defaultForm._id,
        phone: selectedAdminDoc?.phone || defaultForm.phone,
        roles: selectedAdminDoc?.roles || defaultForm.roles,
      });
    }
  }, [selectedAdminDoc]);

  useEffect(() => {
    if (error) {
      toast({
        title: 'Update is unsuccessful!',
        description: error.response?.data?.message,
        status: 'error',
        duration: 3000,
      });
    }
  }, [error, toast]);

  useEffect(() => {
    if (createErrors) {
      toast({
        title: 'Create was unsuccessful!',
        description: createErrors.response?.data?.message,
        status: 'error',
        duration: 3000,
      });
    }
  }, [createErrors, toast]);

  const samePassword = useCallback(
    (strictMode: boolean) => {
      const { password, passwordAgain } = formData;
      if (strictMode || (password && passwordAgain)) {
        if (password !== passwordAgain) {
          return false;
        }
      }
      setErrors('');
      return true;
    },
    [formData],
  );

  const callUpdate = useCallback(async () => {
    try {
      const updatedUserDoc = Object.assign({}, formData, {
        _id: undefined,
        passwordAgain: undefined,
        roles: formData.roles.map(({ value }: { value: string }) => value),
        adminUserId: formData._id,
      }) as UpdateUser;

      try {
        formValidator.parse(formData);
      } catch (error: any) {
        setErrors(error.errors.map((e: any) => e.message).join('\n'));
        return;
      }

      if ((formData.password || formData.passwordAgain) && !samePassword(true)) {
        setErrors('Passwords do not match');
        return;
      }

      await updateAdminUser({ data: updatedUserDoc });
      setFormData(defaultForm);
      onClose(true);
    } catch (e) {
      // handled
    }
  }, [formData, updateAdminUser, setFormData, onClose]);

  const validate = useCallback(
    (field: string) => () => {
      try {
        // @ts-ignore
        validators[field].parse(formData[field]);
      } catch (error: any) {
        setErrors(error.errors.map((e: any) => e.message).join('\n'));
        return;
      }
      setErrors('');
    },
    [formData],
  );

  const callCreate = useCallback(async () => {
    try {
      const newUserDoc = Object.assign({}, formData, {
        _id: undefined,
        passwordAgain: undefined,
        roles: formData.roles.map(({ value }: { value: string }) => value),
      }) as CreateUser;

      try {
        formValidator.parse(formData);
      } catch (error: any) {
        setErrors(error.errors.map((e: any) => e.message).join('\n'));
        return;
      }
      if (!samePassword(true)) {
        setErrors('Passwords do not match');
        return;
      }

      await createAdminUser({ data: newUserDoc });
      setFormData(defaultForm);
      onClose(true);
    } catch (e) {
      // handled
    }
  }, [formData, createAdminUser, setFormData, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setFormData(defaultForm);
        setErrors('');
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id='name'>
            <FormLabel>Name</FormLabel>
            <Input
              value={formData.name ?? ''}
              onChange={(e) => setFormData((prev: any) => ({ ...prev, name: e.target.value }))}
              onBlur={validate('name')}
            />
          </FormControl>

          <FormControl id='email'>
            <FormLabel>Email</FormLabel>
            <Input
              value={formData.email ?? ''}
              onChange={(e) => setFormData((prev: any) => ({ ...prev, email: e.target.value }))}
              onBlur={validate('email')}
            />
          </FormControl>

          <FormControl id='phone'>
            <FormLabel>Phone</FormLabel>
            <Input
              value={formData.phone ?? ''}
              onChange={(e) => setFormData((prev: any) => ({ ...prev, phone: e.target.value }))}
              onBlur={validate('phone')}
            />
          </FormControl>
          <FormControl id='roles'>
            <FormLabel>Roles</FormLabel>
            <Select<SelectSchema, boolean, GroupBase<SelectSchema>>
              isMulti
              options={roleOptions}
              value={formData.roles}
              onChange={(newValue) => setFormData((prev: any) => ({ ...prev, roles: newValue }))}
              onBlur={validate('roles')}
            />
          </FormControl>
          <FormControl id='password'>
            <FormLabel>Password</FormLabel>
            <Input
              type='password'
              value={formData.password ?? ''}
              onChange={(e) => setFormData((prev: any) => ({ ...prev, password: e.target.value }))}
              onBlur={() => samePassword(false)}
            />
          </FormControl>
          <FormControl id='passwordAgain'>
            <FormLabel>Password Again</FormLabel>
            <Input
              type='password'
              value={formData.passwordAgain ?? ''}
              onChange={(e) =>
                setFormData((prev: any) => ({ ...prev, passwordAgain: e.target.value }))
              }
              onBlur={() => samePassword(false)}
            />
          </FormControl>
          {errors && (
            <Alert status='error' my={2}>
              <AlertIcon />
              <AlertTitle mr={2}>{errors}</AlertTitle>
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            isLoading={isUpdateInProgress || isCreateInProgress}
            onClick={toCreate ? callCreate : callUpdate}
          >
            Save
          </Button>
          <Button
            variant='ghost'
            onClick={() => {
              setFormData(defaultForm);
              setErrors('');
              onClose();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
