import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

import { useState } from 'react';
import CreativeCoc from './components/CreativeCoC';
import CreativePenalties from './components/CreativePenalties';
import Invoices from './components/Invoices';

export default function CreativeFinances() {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Text fontSize='25px' fontWeight='bold' m='20px'>
        Photographers finances
      </Text>
      <Tabs index={selectedTab} onChange={handleTabChange}>
        <TabList ml='15px'>
          <Tab>COC</Tab>
          <Tab>Invoices</Tab>
          <Tab>Penalties</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <CreativeCoc />
          </TabPanel>
          <TabPanel>
            <Invoices />
          </TabPanel>
          <TabPanel>
            <CreativePenalties />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
