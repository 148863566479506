import React from "react";

import MenuLayout from "../../layouts/MenuLayout";
import RoleGuard from "../../layouts/RoleGuard";

import {adminRoles} from "../../variables/roles";
import Dashboard from "./Dashboard";


export default function DashboardPage() {
  return <RoleGuard
    page
    roles={
      [
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]
    }
  >
    <MenuLayout page={<Dashboard />} />
  </RoleGuard>
}
