import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  ListItem,
  Text,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';
import {
  useAddItemsToProjectPrices,
  useRemoveAdditionalItemFromProjectPrices,
  useUpdateProjectPrices,
} from 'api/projects';
import {useCallback, useEffect, useState} from 'react';
import {MdDeleteForever, MdModeEdit} from 'react-icons/md';
import {IoAdd} from 'react-icons/io5';
import {CustomFieldStyle} from '../variables/styles';
import {TfiMinus, TfiPlus,} from 'react-icons/tfi';
import {ProjectEvent, ProjectPrice, ProjectPriceType, ProjectState,} from '../constants';
import numeral from 'numeral';


export default function ProjectPriceEditorField(props: {
  label: string;
  type: ProjectPriceType;
  projectData: any;
  refetch: Function;
}) {
  const {
    label,
    type,
    projectData,
    refetch,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [editedPrice, setEditedPrice] = useState<number>();
  const [isAdditionalItem, setIsAdditionalItem] = useState(false);
  const [item, setItem] = useState({name: '', value: ''});
  const [isPositive, setIsPositive] = useState(true);

  const [changeReason, setChangeReason] = useState('');
  const [eventId, setEventId] = useState('');

  const toast = useToast();

  const [{error: updateProjectPricesError}, updateProjectPrices] = useUpdateProjectPrices(projectData._id);
  const [{error: removeAdditionalItemError}, removeAdditionalItem] = useRemoveAdditionalItemFromProjectPrices(projectData._id);
  const [{error: addItemToProjectPriceError}, addItemToProjectPrice] = useAddItemsToProjectPrices(projectData._id);

  const handleEdit = (eventId?: string) => {
    setIsEditing(true);
    setEditedPrice(projectData[type]);
    setChangeReason('');
    if (eventId) {
      setEventId(eventId);
    }
  };

  const handleAdditionalItem = () => {
    setIsAdditionalItem(true);
    setChangeReason('');
  };

  const handleAdditionalItemClose = () => {
    setIsAdditionalItem(false);
    setItem({name: '', value: ''});
  };

  useEffect(() => {
    if (addItemToProjectPriceError) {
      toast({
        title: 'Error during adding price item to project',
        status: 'error',
        duration: 3000,
      });
    }
  }, [addItemToProjectPriceError]);

  useEffect(() => {
    if (removeAdditionalItemError) {
      toast({
        title: 'Error during removing price item from project',
        status: 'error',
        duration: 3000,
      });
    }
  }, [removeAdditionalItemError]);

  useEffect(() => {
    if (updateProjectPricesError) {
      toast({
        title: 'Error during price correction',
        status: 'error',
        duration: 3000,
      });
    }
  }, [updateProjectPricesError]);

  const handleSaveItem = async () => {
    const convertedNumberValue = numeral(item.value).value()
    if (!item.name || !convertedNumberValue) {
      toast({
        title: 'Add item name and correct value!',
        status: 'error',
        duration: 3000,
      });
      return;
    }
    try {
      await addItemToProjectPrice({
        data: {
          value: convertedNumberValue * (isPositive ? 1 : -1),
          name: item.name,
          type,
        },
      });
      toast({
        title: `New item added to project`,
        status: 'success',
        duration: 3000,
      });
      refetch();
      setIsAdditionalItem(false);
    } catch {
    }
  };

  const handleDeleteItem = async (eventId: string) => {
    try {
      await removeAdditionalItem({
        data: {eventId},
      });
      toast({
        title: `Item removed from project`,
        status: 'success',
        duration: 3000,
      });
      refetch();
    } catch {
    }
  };

  const handleSavePrice = async (event?: any) => {
    const currentPrice: number = projectData[type] || event.details.creativeFee || 0;

    if (currentPrice === editedPrice) {
      toast({
        title: "Updated price can't be the same as the current.",
        status: 'error',
        duration: 3000,
      });
      return;
    }

    if (!changeReason) {
      toast({
        title: 'Price correction reason is mandatory.',
        status: 'error',
        duration: 3000,
      });
      return;
    }

    const data = {
      updatedPrice: editedPrice,
      changeReason,
      type,
      eventId,
    };

    try {
      await updateProjectPrices({data});
      toast({
        title: `${type}: ${currentPrice} --> ${editedPrice}`,
        status: 'success',
        duration: 3000,
      });
      refetch();
      setIsEditing(false);
    } catch {
    }
  };

  const additionalEvents = projectData.events.filter(
    (e: any) => e.event === ProjectEvent.ADDITIONAL_PRICE_ITEM && e.details.type === type,
  );

  const onPriceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      let newPrice = parseFloat(e.target.value);

      if (isNaN(newPrice)) {
        newPrice = undefined;
      }

      setEditedPrice(newPrice);
    },
    []
  );

  return (
    <FormControl>
      <FormLabel display='flex' justifyContent={'space-between'}>
        {label}{' '}
        {[ProjectPrice.CLIENT_PRICE, ProjectPrice.CREATIVE_PRICE, ProjectPrice.EDITOR_PRICE].includes(type) &&
          [ProjectState.FINISHED, ProjectState.CANCELED].includes(projectData.status) &&
          '(additional items included)'}
      </FormLabel>
        <Flex>
          {isEditing || isAdditionalItem ?
            <Flex>
              {isAdditionalItem ?
                <Flex direction='column' w='100%'>
                  <Input
                    placeholder='Item'
                    bg='white'
                    value={item.name}
                    mb={4}
                    onChange={(e) => setItem({...item, name: e.target.value})}
                  />
                  <Flex>
                    <IconButton
                      bg='white'
                      onClick={() => setIsPositive((v) => !v)}
                      colorScheme='photonOffWhite'
                      color='photonDeepBlue'
                      aria-label='Change sign'
                      icon={isPositive ? <TfiPlus/> : <TfiMinus/>}
                      mr='2px'
                    />
                    <Input
                      type="number"
                      w='100%'
                      bg='white'
                      placeholder={'0'}
                      value={item.value}
                      mb={4}
                      onChange={(e) => setItem({...item, value: e.target.value})}
                    />
                    <Button
                      variant='photonMedium'
                      ml='5px'
                      onClick={() => {
                        handleAdditionalItemClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='photonLight'
                      mt='0px'
                      ml='5px'
                      onClick={() => handleSaveItem()}
                    >
                      Save
                    </Button>
                  </Flex>
                </Flex>
                : <Flex direction='column' w='100%'>
                    <Input
                      placeholder='Reason of price correction'
                      bg='white'
                      value={changeReason}
                      mb={4}
                      onChange={(e) => setChangeReason(e.target.value)}
                    />
                    <Flex>
                      <Input
                        w='100%'
                        bg='white'
                        placeholder={projectData[type]}
                        value={editedPrice}
                        mb={4}
                        onChange={onPriceChange}
                      />
                      <Button variant='photonMedium' ml='5px' onClick={() => setIsEditing(false)}>
                        Cancel
                      </Button>
                      <Button
                        variant='photonLight'
                        mt='0px'
                        ml='5px'
                        onClick={() => handleSavePrice()}
                      >
                        Save
                      </Button>
                    </Flex>
                  </Flex>
                 }
            </Flex>
            : <Flex direction='column' mb={6}>
                <Flex w='100%' mb='0px'>
                  <Flex {...CustomFieldStyle} mb={0} w='340px'>
                    <Text>{projectData[type]}</Text>
                  </Flex>
                  <Button ml='5px' onClick={() => handleEdit()}>
                    {<MdModeEdit size={20}/>}
                  </Button>
                  <Button ml='5px' w='50px' onClick={handleAdditionalItem}>
                    {<IoAdd size={25}/>}
                  </Button>
                </Flex>
                <Flex>
                  {additionalEvents.length > 0 && (
                    <UnorderedList ml='15px' styleType='""'>
                      {
                        additionalEvents.map(
                          (e: any) => <ListItem mt={2} id={e._id} key={e._id}>
                            <Flex>
                              <Text mr='5px'>{`${e.details.item}: ${e.details.amount} ${projectData.currency} `}</Text>

                              <MdDeleteForever
                                cursor='pointer'
                                size={20}
                                onClick={() => handleDeleteItem(e._id)}
                              />
                            </Flex>
                          </ListItem>
                        )
                      }
                    </UnorderedList>
                  )}
                </Flex>
              </Flex>
          }
        </Flex>
    </FormControl>
  );
}
