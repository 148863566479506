export const statStyles = {
  components: {
    Stat: {
      parts: ['container'],
      baseStyle: {
        container: {
          m: '5px',
          maxWidth: '250px',
          minWidth: '250px',
          height: '100px',
          border: '1px',
          borderColor: 'gray.200',
          borderRadius: 20,
          bg: 'white',
          px: 2,
          py: 4,
        },
        number: {
          display: 'flex',
          alignItems: 'baseline',
        },
      },
      sizes: {},
      variants: {},
      defaultProps: {},
    },
  },
};
