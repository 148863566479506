import {
  Button,
  Grid,
  Flex,
  InputGroup,
  Input,
  Select,
  Text,
  Switch,
  InputRightElement,
} from '@chakra-ui/react';
import { TiDeleteOutline } from 'react-icons/ti';
import { ClientListFilterTypes } from '../types';
import { useState } from 'react';
import Card from 'components/card/Card';
import React from 'react';
import { getCountryName } from '../../../utils/country-codes';
import { useGetClientCountries } from 'api/clients';

const statuses = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

const RemoveInputContent = ({
  handleFilterChange,
  name,
}: {
  handleFilterChange: Function;
  name: string;
}) => {
  return (
    <InputRightElement>
      <Button size='20' onClick={() => handleFilterChange('', name)}>
        <TiDeleteOutline size='20' />
      </Button>
    </InputRightElement>
  );
};

const handleResetFilters = async (
  setFilters: React.Dispatch<React.SetStateAction<ClientListFilterTypes>>,
  handleSearch: (resetFilters: ClientListFilterTypes) => Promise<void>,
  filters: ClientListFilterTypes,
) => {
  const updatedFilters: any = {};
  for (const key in filters) {
    updatedFilters[key] = '';
  }
  setFilters(updatedFilters);
  handleSearch(updatedFilters);
};

const CreativeListFilters = ({
  filters,
  handleFilterChange,
  handleSearch,
  setFilters,
}: {
  filters: ClientListFilterTypes;
  handleFilterChange: Function;
  handleSearch: (resetFilters: ClientListFilterTypes) => Promise<void>;
  setFilters: React.Dispatch<React.SetStateAction<ClientListFilterTypes>>;
}) => {
  const [showFilters, setShowFilters] = useState(true);

  const [{ data: clientCountries }, getClientCountries] = useGetClientCountries();

  return (
    <Card flexDirection='column' p='0px 20px'>
      {showFilters && (
        <Flex width='150px' alignItems='center' m='18px 15px'>
          <Text color='gray.400' mr='20px'>
            Show filters
          </Text>
          <Switch
            isChecked={showFilters}
            colorScheme='brandScheme'
            size='md'
            onChange={() => setShowFilters((prevShowFilters) => !prevShowFilters)}
          />
        </Flex>
      )}

      {showFilters && (
        <Grid
          templateColumns='repeat(auto-fit, minmax(300px, 1fr))'
          gap='10px 10px'
          m='0px 60px 0px 20px'
          justifyItems='center'
          alignItems='center'
        >
          {'search' in filters && (
            <Flex direction='column' mb={4}>
              <Text>Name:</Text>
              <InputGroup w='300px'>
                {filters.search && (
                  <RemoveInputContent handleFilterChange={handleFilterChange} name={'search'} />
                )}
                <Input
                  variant='tableSearch'
                  placeholder='Search by name or email'
                  onChange={(e) => handleFilterChange(e.target.value, 'search')}
                  value={filters.search}
                  onBlur={() => handleSearch(filters)}
                />
              </InputGroup>
            </Flex>
          )}
          {'country' in filters && (
            <Flex direction='column' mb={4} width='300px'>
              <Text>Country:</Text>
              <Select
                onChange={(e) => handleFilterChange(e.target.value, 'country')}
                placeholder='Select country'
                value={filters.country}
              >
                {clientCountries?.map((country: string) => (
                  <option key={country} value={country}>
                    {getCountryName(country)}
                  </option>
                ))}
              </Select>
            </Flex>
          )}
          {'status' in filters && (
            <Flex direction='column' mb={4} width='300px'>
              <Text>Status:</Text>
              <Select
                onChange={(e) => handleFilterChange(e.target.value, 'status')}
                placeholder='All'
                value={filters.status}
              >
                {statuses.map((status: { value: string; label: string }) => (
                  <option key={status.value} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </Select>
            </Flex>
          )}
        </Grid>
      )}
      <Flex
        justifyContent={{ base: 'space-around', lg: 'space-between' }}
        alignItems='center'
        direction={{ base: 'column', lg: 'row' }}
      >
        {showFilters ? (
          <Flex direction='row' width='300px' m='10px 0px 10px 20px'>
            <Button
              onClick={() => handleResetFilters(setFilters, handleSearch, filters)}
              variant='photonMedium'
              ml='20px'
              w='130px'
            >
              Reset filters
            </Button>
          </Flex>
        ) : (
          <Flex width='150px' justifyContent='center' alignItems='center' ml='10px'>
            <Text color='gray.400' mr='20px'>
              Show filters
            </Text>
            <Switch
              isChecked={showFilters}
              colorScheme='brandScheme'
              size='md'
              onChange={() => setShowFilters((prevShowFilters) => !prevShowFilters)}
            />
          </Flex>
        )}
      </Flex>
    </Card>
  );
};
export default CreativeListFilters;
