import MenuLayout from 'layouts/MenuLayout';
import RoleGuard from 'layouts/RoleGuard';
import React from 'react';
import { adminRoles } from 'variables/roles';
import ClientList from './ClientList';

export default function ClientListPage() {
  return (
    <RoleGuard
      page
      roles={[
        adminRoles.RolePhotonManager.key,
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]}
    >
      <MenuLayout page={<ClientList />} />
    </RoleGuard>
  );
}
