import {makeUseAxios} from "axios-hooks";
// import LruCache from 'lru-cache';
import ServerClient from './serverClient';


export const ServerClientInstance = ServerClient();

export default makeUseAxios(
  {
    cache: false,
    // cache: new LruCache(
    //     {
    //         max: 50,
    //         ttl: 1000 * 10,
    //     }
    // ),
    axios: ServerClientInstance,
  }
);
