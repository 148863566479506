import React, { useState } from 'react';
import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

import { useGetCreative } from '../../api/staff-api';
import CreativeBasicData from './components/CreativeBasicData';
import CreativeBillingData from './components/CreativeBillingData';
import CreativeServices from './components/CreativeServices';
import CreativeRating from './components/CreativeRating';
import ProfilePicture from './components/ProfilePicture';
import { useParams } from 'react-router-dom';
import CreativeManage from './components/CreativeManage';
import { getCountryName } from 'utils/country-codes';
import Comments from './components/Comments';
import { useFormContext } from 'contexts/useFormContext';
import { statusColors, newApplicantStates, CreativeTags } from './constants';

const tabLabels = [
  'Basic data',
  'Services',
  'Billing',
  'Projects and rating',
  'Manage',
  'Comments',
];

export default function Creative() {
  const { handleTabChange } = useFormContext();
  const { creativeId } = useParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [{ data: creativeData }, getCreative] = useGetCreative(creativeId);

  const displayCreativeState = [
    ...newApplicantStates,
    { value: 'inactive', label: 'Deactivated' },
  ].find((state: any) => state.value === creativeData?.status);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {creativeData && (
        <>
          <Flex
            bg='white'
            alignItems='center'
            w={{ base: '90vw', md: '500px' }}
            borderRadius='20px'
            m='10px'
          >
            <Box h={{ base: '50px', md: '75px' }} w={{ base: '50px', md: '75px' }} m='10px'>
              <ProfilePicture name={creativeData.name} />
            </Box>
            <Box m='10px 20px' w='80%'>
              <Text fontSize='25px' fontWeight='bold'>
                {creativeData.name}
              </Text>
              <Text>{creativeData.email}</Text>
              <Text>{creativeData.phone}</Text>
              <Flex justifyContent='space-between' w='90%' alignItems='center'>
                <Text>{getCountryName(creativeData.billingAddressObj?.country)}</Text>
                {displayCreativeState ? (
                  <Box
                    borderRadius='20px'
                    p='5px 10px'
                    fontWeight='bold'
                    textAlign='center'
                    bg={statusColors[creativeData.status]?.primary}
                    color={statusColors[creativeData.status]?.secondary}
                  >
                    <Text>{displayCreativeState?.label || creativeData.status}</Text>
                  </Box>
                ) : (
                  <Box
                    bg={creativeData.tags?.includes(CreativeTags.INACTIVE) ? '#ffb03b' : '#3cc427'}
                    fontWeight='bold'
                    color='black'
                    p='10px'
                    borderRadius='20px'
                    w='80px'
                  >
                    <Text align='center'>
                      {creativeData.tags?.includes(CreativeTags.INACTIVE) ? 'Inactive' : 'Active'}
                    </Text>
                  </Box>
                )}
              </Flex>
            </Box>
          </Flex>
          <Tabs index={selectedTabIndex}>
            <TabList ml='0px' mr='20px'>
              {tabLabels.map((label, index) => (
                <Tab
                  key={index}
                  w={{ base: '16%', md: 'auto' }}
                  onClick={() => handleTabChange(index, setSelectedTabIndex)}
                >
                  {label}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel>
                <CreativeBasicData creativeData={creativeData} />
              </TabPanel>

              <TabPanel>
                <CreativeServices creativeData={creativeData} />
              </TabPanel>

              <TabPanel>
                <CreativeBillingData creativeData={creativeData} />
              </TabPanel>

              <TabPanel>
                <CreativeRating creativeData={creativeData} />
              </TabPanel>

              <TabPanel>
                <CreativeManage creativeData={creativeData} callGetCreative={getCreative} />
              </TabPanel>

              <TabPanel>
                <Comments creativeData={creativeData} callGetCreative={getCreative} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </Box>
  );
}
