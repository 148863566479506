
import {Box} from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";
import { currencyFormatter } from "utils/number-formatter";

  export default function PieChart(props: { 
    labels: string[]
    series: number[]
    currency: string
  }) {
 
    const { labels, series, currency} = props;
    const noData = series.every(number => number === 0);

    const options = {
        width: 380,
        labels: labels,
        theme: {
            palette: 'palette1'
          },
        tooltip : {  
          y: {
            formatter: function(value:any) {
              return `${currencyFormatter(value, currency)} ${currency}`;
            }
          }
        },
        title: {
          text: currency,
        }
      }
      
    return (
    !noData && 
      <Box bg ='white' border= ' solid #E2E8F0 1px' borderRadius='20px' height='200px' ml='10px'>
        <ReactApexChart options={options} series={series} type="pie" width={350} />
      </Box>
    );
  }
  