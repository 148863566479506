export const ProjectPrice: Record<string, string> = {
  CLIENT_PRICE: 'clientPrice',
  CREATIVE_PRICE: 'creativePrice',
  EDITOR_PRICE: 'editorPrice',
  CREATIVE_FEE: 'creativeFee',
  CLIENT_CANCELLATION_FEE: 'clientCancellationFee',
  CREATIVE_LATE_FEE: 'creativeLateFee',
  CREATIVE_CONTRACTUAL_FEE: 'creativeContractualFee'
};

type ValueOf<T> = T[keyof T];
export type ProjectPriceType = ValueOf<typeof ProjectPrice>;

export const ProjectState: Record<string, string> = {
  CREATED: 'created',
  STARTED: 'started',
  ON_HOLD: 'on-hold',
  IN_PROGRESS: 'in-progress',
  PHOTOGRAPHER_EDITING: 'photographer-editing',
  AI_POST_PRODUCTION: 'ai-post-production',
  EDITOR_POST_PRODUCTION: 'editor-post-production',
  DELIVERED: 'delivered',
  COMPLAIN_IN_PROGRESS: 'complain-in-progress',
  FINISHED: 'finished',
  CANCELED: 'canceled',
  DELETED: 'deleted',
  POST_PROCESSING: 'post-processing',
  POST_PROCESSING_ADMIN_CONFIRM: 'post-processing-admin-confirm',
  RETOUCHING: 'retouching',
}

export const filterStateOptions: {
  value: string,
  label: string
}[] = Object.values(ProjectState).slice(0, -3).map((value: string) => ({value, label: value}))

export const ProjectEvent: Record<string, string> = {
  ADDITIONAL_PRICE_ITEM: 'additionalPriceItem',
  PRICE_CORRECTION: 'priceCorrection',
  PROJECT_DATA_MODIFICATION: 'projectDataModification',
  CREATIVE_LATE_FEE: 'creativeLateFee',
  CANCEL_BY_CREATIVE: 'cancelByCreative',
  FEE_REMOVAL: 'feeRemoval',
};

export const DATE_CHANGE_REASON : Record<string, {value: string, label:string}> = {
    CLIENT: {value: 'client', label: 'The client/venue'},
    PHOTON: {value: 'photon', label: 'Photon'},
}

export const ContractualPenaltyReason: Record<string, string>={
  LATE_ARRIVAL: 'late-arrival',
  EARLY_LEAVE: 'early-leave',
  OTHER: 'other'
}

export const contractualPenaltyReasons = [
  { value: ContractualPenaltyReason.LATE_ARRIVAL, label: 'the photographer was late for the photo shoot' },
  {
    value: ContractualPenaltyReason.EARLY_LEAVE,
    label: 'the photographer left the location before the end of the shooting',
  },
  { value: ContractualPenaltyReason.OTHER, label: 'other' },
];

