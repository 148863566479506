export const columnsDataClients = [
  {
    Header: 'Name',
    accessor: 'name',
    sorting: true,
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Tax number',
    accessor: 'taxId',
  },
  {
    Header: '',
    accessor: 'action',
  },
];
