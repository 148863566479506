import
  React,
  {
    useState,
  } from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
} from "@chakra-ui/react";

import AdminUsersTable from "./AdminUsersTable";


function AdminUsersTabs() {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  }

  return <Box pt={{ base: "50px", md: "80px", xl: "130px" }}>
    <Tabs index={tabIndex} onChange={handleTabsChange}>
      <TabList>
        <Tab>Active</Tab>
        <Tab>Deleted</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          {
            tabIndex === 0
              ? <AdminUsersTable status='active' />
              : null
          }
        </TabPanel>
        <TabPanel>
          {
            tabIndex === 1
              ? <AdminUsersTable status='deleted' />
              : null
          }
        </TabPanel>
      </TabPanels>
    </Tabs>
  </Box>;
}

export default AdminUsersTabs;
