import { useCallback, useEffect, useState } from 'react';
import { useGetProjectStatistics } from '../../../api/dashboard';
import { Stat, StatLabel, StatNumber, Box, Text, Flex } from '@chakra-ui/react';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import moment from 'moment-timezone';
import PieChart from './charts/PieChart';
import CountryAndClientSelector from './CountryAndClientSelector';
import React from 'react';
import { currencyFormatter } from '../../../utils/number-formatter';

export default function ProjectStats() {
  const [client, setClient] = useState('');
  const [country, setCountry] = useState('');
  const [fromDate, setFromDate] = useState(new Date(moment().startOf('month').toDate()));
  const [toDate, setToDate] = useState(new Date(moment().endOf('month').toDate()));
  const [{ data }, getStatistics] = useGetProjectStatistics();

  const callGetStatistics = useCallback(async () => {
    try {
      await getStatistics({
        params: {
          client,
          country,
          fromDate: moment(fromDate).format('YYYY.MM.DD'),
          toDate: moment(toDate).format('YYYY.MM.DD'),
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [getStatistics, client, country, fromDate, toDate]);

  useEffect(() => {
    callGetStatistics();
  }, [callGetStatistics, client, country, fromDate, toDate]);

  return (
    data && (
      <Box borderRadius='20px' p='20px' border='solid 1px lightgrey' mt='10px'>
        <Box mb='20px'>
          <Text fontSize='xl' align='center' mb='10px'>
            Project statistics
          </Text>
          <Flex>
            <Box bg='white' w='150px' m='5px' borderRadius='10px'>
              <SingleDatepicker 
                date={fromDate} 
                onDateChange={(e) => setFromDate(e)} 
                configs={{ firstDayOfWeek: 1 }}
                />
            </Box>
            <Box bg='white' w='150px' m='5px' borderRadius='10px'>
              <SingleDatepicker 
                date={toDate} 
                onDateChange={(e) => setToDate(e)} 
                configs={{ firstDayOfWeek: 1 }}
                />
            </Box>

            <CountryAndClientSelector
              country={country}
              setCountry={setCountry}
              client={client}
              setClient={setClient}
            />
          </Flex>
        </Box>

        <Flex direction='column'>
          <Text>Projects expected to be finished (ongoing)</Text>
          <Flex>
            <Stat>
              <StatLabel>Expected number of projects to be finished</StatLabel>
              <StatNumber>{data.runningProjectStats?.count}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Expected value of ongoing projects</StatLabel>
              <StatNumber>
                {currencyFormatter(data.runningProjectStats?.EUR, 'EUR')}
                <Text variant='stat'>{'EUR'}</Text>
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Expected value of ongoing projects</StatLabel>
              <StatNumber>
                {currencyFormatter(data.runningProjectStats?.HUF, 'HUF')}
                <Text variant='stat'>{'HUF'}</Text>
              </StatNumber>
            </Stat>
          </Flex>
          <Text>Finished projects</Text>
          <Flex>
            <Stat>
              <StatLabel>Number of finished projects</StatLabel>
              <StatNumber>{data.finishedProjectCount}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Avarage value of finished projects </StatLabel>
              <StatNumber>{data.finishedProjectsAverageRating}</StatNumber>
            </Stat>
            {Object.entries(data.finishedProjectsTotalPricesByCurrency).map(
              ([currency, value], i) => {
                return (
                  <Stat key={i}>
                    <StatLabel>Value of finished projects</StatLabel>
                    <StatNumber>
                      {currencyFormatter(value as number, currency)}
                      <Text variant='stat'>{currency}</Text>
                    </StatNumber>
                  </Stat>
                );
              },
            )}
          </Flex>
          <Text>Cancelled projects</Text>
          <Flex>
            <Stat>
              <StatLabel>Number of cancelled projects</StatLabel>
              <StatNumber>{data.canceledProjectCount}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Num. of cancelled projects with fee</StatLabel>
              <StatNumber>{data.canceledProjectsWithFeeCount}</StatNumber>
            </Stat>
            {Object.entries(data.canceledProjectsTotalPricesByCurrency).map(
              ([currency, value], i) => {
                return (
                  <Stat key={i}>
                    <StatLabel>Value of cancelled projects</StatLabel>
                    <StatNumber>
                      {currencyFormatter(value as number, currency)}
                      <Text variant='stat'>{currency}</Text>
                    </StatNumber>
                  </Stat>
                );
              },
            )}
          </Flex>

          <Flex direction='column'>
            <Text>Revenue and expenses</Text>
            <Flex direction='column'>
              {Object.entries(data.incomeAndExpenses as Object).map(([currency, data], index) => {
                return (
                  <Flex key={index} mb='20px'>
                    <Flex direction='column'>
                      <Stat>
                        <StatLabel>Net revenue</StatLabel>
                        <StatNumber>
                          {currencyFormatter(data.clientPrice as number, currency)}
                          <Text variant='stat'>{currency}</Text>
                        </StatNumber>
                      </Stat>
                      <Stat>
                        <StatLabel>Commission</StatLabel>
                        <StatNumber>
                          {currencyFormatter(data.commission as number, currency)}
                          <Text variant='stat'>{currency}</Text>
                        </StatNumber>
                      </Stat>
                    </Flex>
                    <Flex direction='column'>
                      <Stat>
                        <StatLabel>Photographer's fees</StatLabel>
                        <StatNumber>
                          {currencyFormatter(data.creativePrice as number, currency)}
                          <Text variant='stat'>{currency}</Text>
                        </StatNumber>
                      </Stat>
                      <Stat>
                        <StatLabel>Editors' fees</StatLabel>
                        <StatNumber>
                          {currencyFormatter(data.editorPrice as number, currency)}
                          <Text variant='stat'>{currency}</Text>
                        </StatNumber>
                      </Stat>
                    </Flex>

                    <PieChart
                      labels={["Photographer's fee", "Editor's fee", 'Commission']}
                      series={[data.creativePrice, data.editorPrice, data.commission]}
                      currency={currency}
                    />
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    )
  );
}
