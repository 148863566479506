import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';

import CreativeListTable from './components/CreativeListTable';
import { columnsDataCreative, columnsDataInactiveCreative } from './variables/columnsData';
import { useGetInactiveCreatives } from '../../api/staff-api';
import { CreativeListFilterTypes } from './types';
import { useLocation, useSearchParams } from 'react-router-dom';
import { decodeStringArrayParam } from 'utils/decode-url-params';

export default function InctiveCreativeList() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {
    search = '',
    country = '',
    service = '',
    deactivateReason = '',
  } = Object.fromEntries(queryParams.entries());
  const workingArea = decodeStringArrayParam(queryParams, 'workingArea');

  const [
    { data: creativesData, loading: getCreativesLoading, error: getCreativesError },
    getCreatives,
    cancelGetCreatives,
  ] = useGetInactiveCreatives();

  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1);
  const [perPage, setPerPage] = useState(parseInt(searchParams.get('perPage')) || 10);
  const [sorting, setSorting] = useState({
    field: searchParams.get('sort') || 'name',
    order: parseInt(searchParams.get('sortOrder')) || 1,
  });
  const [filters, setFilters] = useState<CreativeListFilterTypes>({
    search,
    country,
    workingArea,
    service,
    deactivateReason,
  });

  const callGetCreatives = useCallback(
    async (
      page: number,
      perPage: number,
      sorting: { field: string; order: number },
      filters: CreativeListFilterTypes,
    ) => {
      try {
        await getCreatives({
          params: {
            page,
            perPage,
            sorting,
            filters,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getCreatives],
  );

  useEffect(() => {
    callGetCreatives(page, perPage, sorting, filters);
  }, [page, perPage, sorting]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <CreativeListTable
        columnsData={columnsDataInactiveCreative}
        tableData={creativesData?.data}
        total={creativesData?.total}
        page={page}
        perPage={perPage}
        setPerPage={setPerPage}
        setPage={setPage}
        filters={filters}
        setFilters={setFilters}
        callBack={callGetCreatives}
        type='inactive'
        setSorting={setSorting}
        sorting={sorting}
      />
    </Box>
  );
}
