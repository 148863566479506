import {ReactNode} from "react";
import {
  Box,
  Flex,
  Image
} from '@chakra-ui/react';

import PropTypes from 'prop-types';

import Footer from 'components/footer/FooterAuth';


function AuthIllustration(props: { children: ReactNode | string; illustrationBackground: string }) {
	const { children } = props;

  return <Flex position='relative' h='max-content'>
    <Flex
      h={{
        sm: 'initial',
        md: 'unset',
        lg: '100vh',
        xl: '97vh'
      }}
      w='100%'
      maxW={{ md: '66%', lg: '1313px' }}
      mx='auto'
      pt={{ sm: '50px', md: '0px' }}
      px={{ lg: '30px', xl: '0px' }}
      ps={{ xl: '70px' }}
      justifyContent='start'
      direction='column'
    >
      {children}
      <Box
        display={{ base: 'none', md: 'block' }}
        h='100%'
        minH='100vh'
        w={{ lg: '50vw', '2xl': '44vw' }}
        position='absolute'
        right='0px'
      >
        <Flex
          bg='#6b52ff'
          justify='center'
          align='center'
          w='100%'
          h='100%'
          bgSize='cover'
          bgPosition='50%'
          position='absolute'
          borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
        >
          <Image width='70%' mb='100px' src='/photon_logo_white.png' />
        </Flex>
      </Box>
      <Footer />
    </Flex>
  </Flex>;
}

AuthIllustration.propTypes = {
	illustrationBackground: PropTypes.string,
	image: PropTypes.any
};

export default AuthIllustration;
