
import {Box} from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { currencyFormatter } from "utils/number-formatter";

  export default function StackedColumnChart(props: { 
    labels: string[]
    numbers: number[][]
    axisLabels: string[]
    width: string
    unit: string
  }) {
 
    const { labels, numbers, axisLabels, width, unit} = props;

    const chartData: ApexOptions = {
      chart: {
        type: "bar",
        toolbar: {
          show: false
        },
      },
      colors:['#2fc91a', '#e02828', '#0797f7'],
      xaxis: {
        categories: axisLabels,
        labels: {
            formatter: (value) => {
                return value && value.padStart(2, '0');
            },
        },
    },
    
      yaxis: {
        labels: {
          formatter: (value) => {
            return  ['HUF', 'EUR'].includes(unit) ? `${currencyFormatter(value, unit)} ${unit}` :  `${value} ${unit}` ;
          },
        },
      },
      series: labels.map((label, index) => {
        return {
          name: label,
          type: "column",
          data: numbers[index],
        };
      })
    };
  
    return (
      <Box bg ='white' border= ' solid #E2E8F0 1px' borderRadius='20px'  m='10px'>
        <ReactApexChart options={chartData} series={chartData.series} width={width}   />
      </Box>
    );
  }
  