import React, { useState } from 'react';
import { Text, Box } from '@chakra-ui/react';
import RecruitmentTable from './RecruitmentTable';

function Recruitment() {
  return (
    <Box pt={{ base: '60px', xl: '10px' }}>
      <RecruitmentTable />
    </Box>
  );
}

export default Recruitment;
