import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'


const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const rowStyle = definePartsStyle(
  {
    tr: {
      borderBottomColor: 'brand.600',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
    },
  }
)

export const tableTheme = defineMultiStyleConfig(
  {
    variants: {
      underlineRows: rowStyle
    }
  }
);
