import {useRef} from 'react';

import { LRUCache } from 'lru-cache';

import config from 'config';


const cache = new LRUCache<string, string[] | undefined, undefined>({max: 500});

export const getCityList = async (input: string): Promise<any> => {
  const searchText = input?.trim().toLowerCase();
  let cities: string[] = [];

  if (!searchText) {
    return cities;
  }

  try {
    if ((cities = cache.get(searchText) ?? []).length !== 0) {
      return cities;
    }

    const response = await fetch(`${config.landingUrl}/api/cities?s=${searchText}`);
    cities = await response.json();

    cache.set(searchText, cities);
  } catch (e) {
    console.error(e);
  }

  return cities;
};

export const useLoadCityOptionsWithDebounce = () => {
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>();

  return (input: string, callback: (options: any) => void) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      getCityList(input)
        .then((data) => callback(data))
        .catch(() => callback([]));
    }, 300);
  };
};

export const citySelectorStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '300px',
  }),
  input: (provided: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    px: '14px',
    color: 'black',
  }),
};
