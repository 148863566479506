export const decodeStringArrayParam = (queryParams: any, name: string) => {
  let value = Object.fromEntries(queryParams.entries())[name] || ([] as any);
  if (typeof value === 'string') {
    value = value.split(',').map((v) => decodeURIComponent(v).replace('-', ','));
  }
  return value;
};

export const decodeStringParam = (queryParams: any, name: string) => {
  let value = Object.fromEntries(queryParams.entries())[name] || ([] as any);
  value = decodeURIComponent(value).replace('-', ',');
  return value;
};
