import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import './assets/css/App.css';
import theme from './theme/theme';
import { router } from './App';
import { AuthProvider } from 'hooks/useAuth';
import { FormProvider } from 'contexts/useFormContext';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <FormProvider>
          <RouterProvider router={router} />
        </FormProvider>
      </AuthProvider>
    </ChakraProvider>
  </React.StrictMode>,
);
