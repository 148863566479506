import { useCallback, useEffect, useState } from 'react';
import { useGetAnnualIncome } from 'api/dashboard';
import { Box, Text, Flex } from '@chakra-ui/react';
import moment from 'moment';
import StackedColumnChart from './charts/StackedColumnChart';
import YearSelector from './YearSelector';
import CountrySelector from './CountrySelector';

export default function AnualIncome() {
  const [year, setYear] = useState(moment().format('YYYY'));
  const [country, setCountry] = useState('');
  const [{ data }, getAnualIncome] = useGetAnnualIncome();

  const callGetAnualIncome = useCallback(
    async (country: string, year: string) => {
      try {
        await getAnualIncome({
          params: { year, country },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getAnualIncome, year],
  );

  useEffect(() => {
    if (year) {
      callGetAnualIncome(country, year);
    }
  }, [callGetAnualIncome, year, country]);

  return (
    data && (
      <Box borderRadius='20px' p='10px' border='solid 1px lightgrey' mt='10px'>
        <Text fontSize='xl' align='center' mb='10px'>
          Annual revenue and expenses
        </Text>
        <Flex>
          <YearSelector year={year} setYear={setYear} />
          <CountrySelector country={country} setCountry={setCountry} />
        </Flex>

        <Flex m='10px' justifyContent='space-around'>
          {Object.entries(data).map(([currency, year], i) => {
            return (
              <Flex direction={'column'} key={i}>
                <Text textAlign='center'>{currency}</Text>
                <StackedColumnChart
                  labels={['Revenue', 'Contractor fees', 'Commission']}
                  width='500px'
                  axisLabels={Object.keys(year)}
                  unit={currency}
                  numbers={[
                    Object.values(year).map((data) => data.clientPrice),
                    Object.values(year).map((data) => data.creativeAndEditorPrice),
                    Object.values(year).map((data) => data.commission),
                  ]}
                />
              </Flex>
            );
          })}
        </Flex>
      </Box>
    )
  );
}
