import { useCallback, useEffect, useState } from 'react';
import { useGetTopClientsByIncome, useGetTopClientsByProjectNumber } from 'api/dashboard';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import moment from 'moment';
import YearSelector from './YearSelector';
import { currencyFormatter } from 'utils/number-formatter';

export default function TopClients() {
  const [year, setYear] = useState(moment().format('YYYY'));

  const [{ data: clientsByIncome }, getClientsByIncome] = useGetTopClientsByIncome();

  const [{ data: clientsByProjectNumber }, getClientsByProjectNumber] =
    useGetTopClientsByProjectNumber();

  const callGetClients = useCallback(async () => {
    try {
      await getClientsByIncome({ params: { year } });
      await getClientsByProjectNumber({ params: { year } });
    } catch (e) {
      console.log(e);
    }
  }, [getClientsByIncome, getClientsByProjectNumber, year]);

  useEffect(() => {
    callGetClients();
  }, [callGetClients, year]);

  return (
    <Box borderRadius='20px' p='10px' border='solid 1px lightgrey' mt='10px'>
      <Text fontSize='xl' align='center' mb='10px'>
        {' '}
        Client toplists
      </Text>
      <YearSelector year={year} setYear={setYear} />
      <Flex direction='column' justifyContent='space-around'>
        {clientsByIncome && Object.keys(clientsByIncome).length > 0 && (
          <TableContainer bg='white' borderRadius='20px' p='10px' mb='10px'>
            <Table size='sm'>
              <TableCaption>Top 10 clients by revenue</TableCaption>
              <Thead>
                <Tr>
                  <Th>Client</Th>
                  <Th>Net revenue</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.entries(clientsByIncome).map(([client, income], i) => {
                  return (
                    <Tr key={i}>
                      <Td>{client}</Td>
                      <Td>{currencyFormatter(income as number, 'EUR') + ' EUR'}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {clientsByProjectNumber && Object.keys(clientsByProjectNumber).length > 0 && (
          <TableContainer bg='white' borderRadius='20px' p='10px'>
            <Table size='sm'>
              <TableCaption>Top 10 clients by number of projects</TableCaption>
              <Thead>
                <Tr>
                  <Th>Client</Th>
                  <Th>Number of projects</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.entries(clientsByProjectNumber).map(([client, projectNumber], i) => {
                  return (
                    <Tr key={i}>
                      <Td>{client}</Td>
                      <Td>{projectNumber as number}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Flex>
    </Box>
  );
}
