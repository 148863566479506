import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';

import { useDeleteTemplate } from 'api/photoroom-templates';


export default function DeleteTemplateModal(
  {
    deleteData,
    isOpen,
    onClose,
  }: {
    isOpen: boolean;
    onClose: (successful?: boolean) => void;
    deleteData: string;
  }
) {
  const [{ loading: isDeleteInProgress, error: deleteErrors }, delteTemplate] =
  useDeleteTemplate(deleteData);
  const toast = useToast();

  useEffect(() => {
    if (deleteErrors) {
      toast({
        title: 'Delete was unsuccessful!',
        description: deleteErrors.response?.data?.message,
        status: 'error',
        duration: 3000,
      });
    }
  }, [deleteErrors, toast]);

  const callDelete = useCallback(async () => {
    try {
      await delteTemplate({ data: { _id: deleteData } });
      onClose(true);
    } catch (e) {}
  }, [delteTemplate]);

  return (
    <Modal isOpen={isOpen && !!deleteData} onClose={() => onClose()}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete template</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to delete this template?</ModalBody>
        <ModalFooter>
          <Button onClick={() => onClose()} variant='photonMedium'>
            Cancel
          </Button>
          <Button mr={3} isLoading={isDeleteInProgress} onClick={callDelete} variant='photonLight'>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
