import React from 'react';

import MenuLayout from '../../../layouts/MenuLayout';
import RoleGuard from '../../../layouts/RoleGuard';

import { adminRoles } from '../../../variables/roles';
import ClientFinances from './ClientFinances';

export default function ClientFinancesPage() {
  return (
    <RoleGuard page roles={[adminRoles.RolePhotonAdmin.key, adminRoles.RolePhotonSuperAdmin.key]}>
      <MenuLayout page={<ClientFinances />} />
    </RoleGuard>
  );
}
