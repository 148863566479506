/* eslint-disable */

import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import React from 'react';

export function SidebarLinks(props: { routes: RoutesType[] }) {
  let location = useLocation();
  let activeColor = useColorModeValue('gray.700', 'white');
  let activeIcon = useColorModeValue('brand.500', 'white');
  let textColor = useColorModeValue('secondaryGray.500', 'white');
  let brandColor = useColorModeValue('brand.500', 'brand.400');

  const { routes } = props;

  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  const SimpleRoute = (props: { route: any; index: number }) => {
    const { route, index } = props;

    return (
      <NavLink key={index} to={route.path}>
        <Box>
          <HStack
            spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'}
            py='5px'
            ps='10px'
          >
            <Flex w='100%' alignItems='center' justifyContent='center'>
              <Flex
                color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
                me='18px'
                w='20px'
                justifyContent='center'
                alignItems='center'
              >
                {route.icon}
              </Flex>
              <Text
                me='auto'
                color={activeRoute(route.path.toLowerCase()) ? activeColor : textColor}
                fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}
              >
                {route.name}
              </Text>
            </Flex>
            <Box
              h='36px'
              w='4px'
              bg={activeRoute(route.path.toLowerCase()) ? brandColor : 'transparent'}
              borderRadius='5px'
            />
          </HStack>
        </Box>
      </NavLink>
    );
  };

  const CollectorRoute = (props: { route: any; index: number }) => {
    const { route, index } = props;
    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const location = useLocation();
    const isSubRouteUsed = location.pathname.startsWith(route.path);

    useEffect(() => {
      const isOpen = isSubRouteUsed;
      setOpen(isOpen);
    }, [location.pathname, route.path]);

    const handleMouseEnter = () => {
      setHover(true);
    };

    const handleMouseLeave = () => {
      setHover(false);
    };

    const handleClick = () => {
      setOpen((prevState) => !prevState);
    };

    return (
      <>
        <HStack
          spacing={isSubRouteUsed ? '22px' : '26px'}
          py='5px'
          ps='10px'
          cursor='pointer'
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Flex w='100%' alignItems='center' justifyContent='center'>
            <Box color={isSubRouteUsed ? activeIcon : textColor} me='18px'>
              {route.icon}
            </Box>
            <Text
              me='auto'
              color={isSubRouteUsed ? activeColor : textColor}
              fontWeight={isSubRouteUsed || hover ? 'bold' : 'normal'}
            >
              {route.name}
            </Text>
            <Box color={isSubRouteUsed || hover ? activeIcon : textColor} me='18px'>
              {<IoIosArrowDown />}
            </Box>
          </Flex>
          <Box
            h='36px'
            w='4px'
            bg={isSubRouteUsed ? brandColor : 'transparent'}
            borderRadius='5px'
          />
        </HStack>
        <Box>
          {(open || isSubRouteUsed) &&
            route.subRoutes &&
            route.subRoutes.map((route: RoutesType, index: number) => (
              <SimpleRoute key={route.name} route={route} index={index} />
            ))}
        </Box>
      </>
    );
  };

  const createLinks = (routes: RoutesType[]) =>
    routes.map((route: RoutesType, index: number) =>
      route.subRoutes ? (
        <CollectorRoute key={index} route={route} index={index} />
      ) : (
        <SimpleRoute key={index} route={route} index={index} />
      ),
    );

  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
