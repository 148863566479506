import { useCallback, useEffect, useState } from 'react';
import { useGetAnnualProjects, useGetAverageStats } from 'api/dashboard';
import { Stat, StatLabel, StatNumber, Box, Text, Flex } from '@chakra-ui/react';
import moment from 'moment';
import StackedColumnChart from './charts/StackedColumnChart';
import YearSelector from './YearSelector';
import CountryAndClientSelector from './CountryAndClientSelector';
import { currencyFormatter } from 'utils/number-formatter';

export default function AverageStats() {
  const [client, setClient] = useState('');
  const [country, setCountry] = useState('');
  const [year, setYear] = useState(moment().format('YYYY'));

  const [{ data }, GetAverageStats] = useGetAverageStats();

  const [{ data: anualProjects }, getAnualProjects] = useGetAnnualProjects();

  const callGetAnualProjects = useCallback(async () => {
    try {
      await getAnualProjects({
        params: { year, country, client },
      });
    } catch (e) {
      console.log(e);
    }
  }, [getAnualProjects, year, country, client]);

  useEffect(() => {
    callGetAnualProjects();
  }, [callGetAnualProjects, year]);

  const callGetAverageStats = useCallback(async () => {
    try {
      await GetAverageStats({
        params: {
          client,
          country,
          year,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [GetAverageStats, client, country, year]);

  useEffect(() => {
    callGetAverageStats();
  }, [callGetAverageStats, client, country, year]);

  return (
    <Box borderRadius='20px' p='10px' border='solid 1px lightgrey' mt='10px'>
      <Box mb='20px'>
        <Text fontSize='xl' align='center' mb='10px'>
          Clients averages
        </Text>
        <Flex>
          <YearSelector year={year} setYear={setYear} />
          <CountryAndClientSelector
            country={country}
            setCountry={setCountry}
            client={client}
            setClient={setClient}
          />
        </Flex>
      </Box>
      <Flex justifyContent='space-around'>
        {data?.averageDatas &&
          Object.entries(data.averageDatas as Object).map(([currency, data]) => {
            return (
              <Flex direction='column'>
                <Stat>
                  <StatLabel>Average number of projects ({currency})</StatLabel>
                  <StatNumber>{data.averageProjectNumber}</StatNumber>
                </Stat>
                <Stat>
                  <StatLabel>Average monthly revenue</StatLabel>
                  <StatNumber>
                    {currencyFormatter(data.averageIncome, currency)}
                    <Text variant='stat'>{currency}</Text>
                  </StatNumber>
                </Stat>
                <Stat>
                  <StatLabel>Average monthly commission</StatLabel>
                  <StatNumber>
                    {currencyFormatter(data.averageCommission, currency)}
                    <Text variant='stat'>{currency}</Text>
                  </StatNumber>
                </Stat>
              </Flex>
            );
          })}
      </Flex>
      <Flex justifyContent={'center'}>
        <Stat>
          <StatLabel>Average photographer's rating</StatLabel>
          <StatNumber>{data?.averageRating || 0}</StatNumber>
        </Stat>
      </Flex>
      {anualProjects && (
        <Box p='10px' mt='10px'>
          <Text fontSize='xl' align='center'>
            Finished and cancelled projects per year
          </Text>
          {Object.keys(anualProjects).length > 0 && (
            <Flex m='10px'>
              <Flex direction={'column'}>
                <StackedColumnChart
                  labels={['Finished', 'Canceled']}
                  width='500px'
                  axisLabels={Object.keys(anualProjects)}
                  unit={'db'}
                  numbers={[
                    (Object.values(anualProjects) as any).map((item: any) => item.finished),
                    (Object.values(anualProjects) as any).map((item: any) => item.canceled),
                  ]}
                />
              </Flex>
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
}
