import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Select,
  Link
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import {  useParams } from 'react-router-dom';
import { useUpdateClient } from 'api/clients';


const industryCategories= [
  'event',
  'food',
  'hospitality',
  'ecommerce',
  'other',
]


const orderAmountCategories= [
  'lessThan15',
  '15to25',
  'moreThan25',
  'notSure',
  'oneTime',
]

export default function ClientBranding(props: { clientData: any }) {
  const { clientData } = props;
  const { clientId } = useParams();
  const toast = useToast();

  const defaultValues = {
    chatEnabled: clientData.chatEnabled,
    orderAmountCategory: clientData.orderAmountCategory,
    industryCategory: clientData.industryCategory,
  };

  const {
    handleSubmit,
    register,
  } = useForm({
    defaultValues,
  });

  const [{ error: updateClientError, loading }, updateClient] = useUpdateClient(clientId);

  const handleSaveClick = async (data: any) => {
    try{
      await updateClient({data});
      toast({
          title: 'Client updated',
          status: 'success',
          duration: 3000,
      });
    }catch{}
  };

  useEffect(
    () => {
      if (updateClientError) {
        toast(
          {
            title: 'Update is unsuccessful!',
            status: 'error',
            duration: 3000,
          }
        )
      }
    },
    [updateClientError, toast]
  );

  return (
    <Box mt='50px'>
      <form onSubmit={handleSubmit(handleSaveClick)}>
        <Flex justifyContent='space-around' direction={{ base: 'column', md: 'row' }}>
          <Flex direction='column' alignItems={'center'} justifyContent={'center'}>
            <FormControl id='chatEnabled' mb={4}>
              <FormLabel>Chat enabled</FormLabel>
              <Switch defaultChecked={clientData.chatEnabled} ml='10px' {...register('chatEnabled')}></Switch>
            </FormControl>
            <FormControl id='industryCategory' mb={4} w='400px'>
              <FormLabel>Industry category</FormLabel>
              <Select {...register('industryCategory')}  alignItems='center' defaultValue='en' bg='white' borderRadius='15px'>
                {industryCategories.map(c=> <option key={c} value={c}>{c}</option>)}
              </Select>
            </FormControl>
            <FormControl id='orderAmountCategory' mb={4}>
              <FormLabel>Order amount category</FormLabel>
              <Select {...register('orderAmountCategory')}  alignItems='center' defaultValue='en' bg='white' borderRadius='15px'>
                {orderAmountCategories.map(c=> <option key={c} value={c}>{c}</option>)}
              </Select>
            </FormControl>
          </Flex>
        </Flex>
        <Flex justifyContent='center' m='50px'>
          <Button isLoading={loading} type='submit' variant='photonDark' w='200px'>
            Save
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
