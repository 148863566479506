import { Select } from '@chakra-ui/react';
import { useGetClientCountries } from 'api/clients';
import { getCountryName } from 'utils/country-codes';

export default function CountrySelector(props: { country: string; setCountry: Function }) {
  const { country, setCountry } = props;

  const handleCountryChange = (country: string) => {
    setCountry(country);
  };
  const [{ data: countries }] = useGetClientCountries();

  return (
    <Select
      placeholder='All countries'
      value={country}
      bg='white'
      onChange={(e) => handleCountryChange(e.target.value)}
      w='170px'
      m='5px'
    >
      {countries?.map((countryCode: string) => {
        return (
          countryCode && (
            <option value={countryCode} key={countryCode}>
              {getCountryName(countryCode)}
            </option>
          )
        );
      })}
    </Select>
  );
}
