import useAxios from "./basic/use-axios";
import {UseAxiosResult} from "axios-hooks";
import {AxiosResponse} from "axios";


export const useGetAdminUsers = (status: 'deleted' | 'active') =>
  useAxios(
    {
      url: `/admin/user/admin-users?status=${status}`,
      method: "GET",
    },
  );

export type CreateUser = {
  name: string;
  email: string;
  phone: string;
  password: string;
  roles: string[];
};

export type UpdateUser = CreateUser & {
  adminUserId: string;
};

export type ServerResponse = {
  message: string;
};

export const useCreateAdminUsers = (): UseAxiosResult<AxiosResponse, CreateUser, ServerResponse>  =>
  useAxios(
    {
      url: "/admin/user/admin-users",
      method: "POST",
    },
    { manual: true },
  );

export const useUpdateAdminUsers = (): UseAxiosResult<AxiosResponse, UpdateUser, ServerResponse> =>
  useAxios(
    {
      url: "/admin/user/admin-update",
      method: "PUT",
    },
    { manual: true },
  );

export const useDeleteAdminUsers = (id: string) =>
  useAxios(
    {
      url: `/admin/user/admin-delete/${id}`,
      method: "DELETE",
    },
    { manual: true },
  );
