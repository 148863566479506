import { Outlet } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import {
  useBeforeUnload,
  useLocation,
  unstable_usePrompt as usePrompt,
} from 'react-router-dom';
import { useFormContext } from 'contexts/useFormContext';

const confirmMessage = 'You have unsaved changes! Are you sure you want to leave?';

export const FormLayout = () => {
  const { isFormDirty, setIsFormDirty } = useFormContext();
  const handleBeforeUnload = useCallback(
    (e: any) => {
      if (isFormDirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
    [isFormDirty],
  );

  useBeforeUnload(handleBeforeUnload);

  usePrompt({
    when: isFormDirty,
    message: confirmMessage,
  });

  const location = useLocation();

  useEffect(() => {
    setIsFormDirty(false);
  }, [location]);

  return <Outlet />;
};
