import { Box, Flex, Link, Text, useColorModeValue, Image } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import {NavLink} from "react-router-dom";


export default function AdminNavbar(props: {
  secondary: boolean;
  message: string | boolean;
  brandText: string;
  logoText: string;
  fixed: boolean;
  onOpen: (...args: any[]) => any;
}) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', changeNavbar);

    return () => {
      window.removeEventListener('scroll', changeNavbar);
    };
  });

  const { secondary } = props;

  let mainText = useColorModeValue('navy.700', 'white');
  let navbarPosition = 'fixed' as const;
  let navbarFilter = 'none';
  let navbarBackdrop = 'blur(20px)';
  let navbarShadow = 'none';
  let navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');
  let paddingX = '15px';
  let gap = '0px';
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition='center'
      backgroundSize='cover'
      backgroundColor='#6b52ff'
      transitionDelay='0s, 0s, 0s, 0s'
      transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
      transition-property='box-shadow, background-color, filter, border'
      transitionTimingFunction='linear, linear, linear, linear'
      alignItems={{ xl: 'center' }}
      display={secondary ? 'block' : 'flex'}
      maxH='110px'
      justifyContent={{ xl: 'center' }}
      lineHeight='25.6px'
      mx='auto'
      pb='8px'
      px={{
        sm: paddingX,
        md: '10px',
      }}
      ps={{
        xl: '12px',
      }}
      pt='8px'
      borderBottom='solid white 2px'
      w='100%'
      zIndex={5}
    >
      <Flex
        w='100%'
        flexDirection={{
          sm: 'row',
        }}
        alignItems={{ xl: 'center' }}
        mb={gap}
      >
        <Flex alignItems='center'>
          <Link as={NavLink} to='/'>
            <Image src='/photon_logo_white.png' alt='Photon' maxH='70px' mt='10px' mb='10px' />
          </Link>
          <Text
            color={mainText}
            bg='inherit'
            borderRadius='inherit'
            fontSize='34px'
            mt='60PX'
            ml='90px'
          ></Text>
        </Flex>
        <Flex ms='auto' w={'unset'} alignItems='center'>
          <AdminNavbarLinks onOpen={props.onOpen} secondary={props.secondary} fixed={props.fixed} />
        </Flex>
      </Flex>
    </Box>
  );
}
