import {
  Button,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Textarea,
  Flex,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useCancelProject, useCancelProjectByCreative } from '../../../api/projects';
import { ImCancelCircle } from 'react-icons/im';
import React from 'react';

const CancelProjectButton = ({
  project,
  refetchTableData,
  withButton,
}: {
  project: any;
  refetchTableData: Function;
  withButton?: boolean;
}) => {
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const toast = useToast();
  const [reason, setReason] = useState<string>('');
  const [cancelBy, setCancelBy] = useState<string>('');

  const [{ error: cancelProjectError, loading: cancelProjectLoading }, cancelProject] =
    useCancelProject(project._id);
  const [
    { error: cancelProjectByCreativeError, loading: cancelByCreativeLoading },
    cancelProjectByCreative,
  ] = useCancelProjectByCreative(project._id);

  const handleCancelProject = async () => {
    if (cancelBy === 'client') {
      await cancelProject({
        data: {
          reason,
        },
      });
    } else {
      await cancelProjectByCreative({
        data: {
          reason,
        },
      });
    }

    if (cancelProjectError || cancelProjectByCreativeError) {
      toast({
        title: 'Error during project cancellation',
        status: 'error',
        duration: 3000,
      });
    } else {
      toast({
        title: 'Project cancelled succesfully',
        status: 'success',
        duration: 3000,
      });
    }
    refetchTableData();

    onClose2();
    onClose1();
  };

  const handleSelectCancellation = (type: string) => {
    setCancelBy(type);
    onOpen2();
  };

  return (
    <Box>
      {!['delivered', 'finished', 'deleted', 'canceled'].includes(project.status) && (
        <Flex
          w={withButton ? '200px' : '50px'}
          h='50px'
          justifyContent='center'
          alignItems='center'
          color='#a6a3ff'
          _hover={{ color: '#6b52ff' }}
        >
          {withButton ? (
            <Button variant='photonDark' w='200px' cursor='pointer' onClick={onOpen1}>
              Cancel
            </Button>
          ) : (
            <ImCancelCircle
              size='25px'
              cursor='pointer'
              onClick={onOpen1}
              className='cancel-icon'
            />
          )}
        </Flex>
      )}

      <Modal isOpen={isOpen1} onClose={onClose1} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader> Choose on whose behalf the project will be canceled</ModalHeader>
          <ModalBody display='flex' justifyContent='space-around' mb='20px'>
            <Button
              w='200px'
              variant='photonMedium'
              mr={3}
              onClick={() => handleSelectCancellation('creative')}
            >
              Cancel by creative
            </Button>
            <Button
              w='200px'
              variant='photonMedium'
              mr={3}
              onClick={() => handleSelectCancellation('client')}
            >
              Cancel by client
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen2} onClose={onClose2} size='xl'>
        <ModalOverlay />
        <ModalContent minWidth='1000px'>
          <ModalHeader> Provide the reason of the cancellation!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea onChange={(e) => setReason(e.target.value)}></Textarea>
          </ModalBody>
          <ModalFooter>
            <Button variant='photonMedium' mr={3} onClick={onClose2}>
              Back
            </Button>
            <Button
              variant='photonDark'
              mr={3}
              onClick={handleCancelProject}
              isDisabled={!reason}
              isLoading={cancelByCreativeLoading || cancelProjectLoading}
            >
              Finish cancellation
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default CancelProjectButton;
