import { adminRoles } from '../../variables/roles';
import RoleGuard from '../../layouts/RoleGuard';
import MenuLayout from '../../layouts/MenuLayout';
import InctiveCreativeList from './InactiveCreativeList';
import NewApplicantCreativeList from './NewApplicantCreativeList';

export default function NewApplicantCreativesPage() {
  return (
    <RoleGuard
      page
      roles={[
        adminRoles.RolePhotonManager.key,
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]}
    >
      <MenuLayout page={<NewApplicantCreativeList />} />
    </RoleGuard>
  );
}
