import React, { KeyboardEvent } from 'react';

import moment from 'moment';

import {
  InputLeftElement,
  Button,
  Grid,
  Flex,
  InputGroup,
  Input,
  Select,
  Text,
} from '@chakra-ui/react';

import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { TiDeleteOutline } from 'react-icons/ti';

import { ProjectRatingFilters } from '../../dashboard/types';

const RemoveInputContent = ({
  handleFilterChange,
  name,
}: {
  handleFilterChange: Function;
  name: string;
}) => {
  return (
    <InputLeftElement>
      <Button size='20' onClick={() => handleFilterChange('', name)}>
        <TiDeleteOutline size='20' />
      </Button>
    </InputLeftElement>
  );
};

const handleResetFilters = async (
  setFilters: React.Dispatch<React.SetStateAction<ProjectRatingFilters>>,
  handleSearch: (resetFilters: ProjectRatingFilters) => Promise<void>,
) => {
  const updatedFilters = {
    from: new Date('2023-01-01'),
    to: new Date(),
    creative: '',
    editor: '',
    rating: '',
    search: '',
  };
  setFilters(updatedFilters);
  handleSearch(updatedFilters);
};

const RatingFilters = ({
  filters,
  handleFilterChange,
  handleSearch,
  setFilters,
}: {
  filters: ProjectRatingFilters;
  handleFilterChange: Function;
  handleSearch: (resetFilters: ProjectRatingFilters) => Promise<void>;
  setFilters: React.Dispatch<React.SetStateAction<ProjectRatingFilters>>;
}) => {
  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(filters);
    }
  };

  return (
    <Grid templateColumns='repeat(3, 1fr)' gap={4} width='100%' ml='10px' mr='10px'>
      <Flex direction='column' mb={4}>
        <Text>Project/ client name</Text>
        <InputGroup>
          {filters.search && (
            <RemoveInputContent handleFilterChange={handleFilterChange} name={'search'} />
          )}
          <Input
            paddingLeft='30px'
            variant='tableSearch'
            placeholder='Search by project name or client'
            onChange={(e) => handleFilterChange(e.target.value, 'search')}
            value={filters.search}
            onKeyDown={handleEnterPress}
            onBlur={() => handleSearch(filters)}
          />
        </InputGroup>
      </Flex>
      <Flex direction='column' mb={4}>
        <Text>Photographer:</Text>
        <InputGroup>
          {filters.creative && (
            <RemoveInputContent handleFilterChange={handleFilterChange} name={'creative'} />
          )}
          <Input
            paddingLeft='30px'
            variant='tableSearch'
            placeholder='Search by creative'
            onChange={(e) => handleFilterChange(e.target.value, 'creative')}
            value={filters.creative}
            onKeyDown={handleEnterPress}
            onBlur={() => handleSearch(filters)}
          />
        </InputGroup>
      </Flex>
      <Flex direction='column' mb={4}>
        <Text>Editor:</Text>
        <InputGroup>
          {filters.editor && (
            <RemoveInputContent handleFilterChange={handleFilterChange} name={'editor'} />
          )}
          <Input
            paddingLeft='30px'
            variant='tableSearch'
            placeholder='Serach by editor'
            onChange={(e) => handleFilterChange(e.target.value, 'editor')}
            value={filters.editor}
            onKeyDown={handleEnterPress}
            onBlur={() => handleSearch(filters)}
          />
        </InputGroup>
      </Flex>
      <Flex direction='column' mb={4} width='300px'>
        <Text>Rating:</Text>
        <Select
          placeholder='Search by rating'
          onChange={(e) => handleFilterChange(e.target.value, 'rating')}
          value={filters.rating}
        >
          <option value={4}>4</option>
          <option value={3}>3</option>
          <option value={2}>2</option>
          <option value={1}>1</option>
        </Select>
      </Flex>
      <Flex direction='column' mb={4} width='300px'>
        <Text>{'Time of rating (from):'}</Text>
        <SingleDatepicker
          date={filters.from}
          onDateChange={(e) => handleFilterChange(moment(e).startOf('day').toDate(), 'from')}
          configs={{ firstDayOfWeek: 1 }} 
          />
      </Flex>
      <Flex direction='column' mb={4} width='300px'>
        <Text>{'Time of rating (to):'}</Text>
        <SingleDatepicker
          date={filters.to}
          onDateChange={(e) => handleFilterChange(moment(e).endOf('day').toDate(), 'to')}
          configs={{ firstDayOfWeek: 1 }}
        />
      </Flex>

      <Button
        onClick={() => handleResetFilters(setFilters, handleSearch)}
        variant='photonMedium'
        mb='20px'
        w='130px'
      >
        Reset filters
      </Button>
    </Grid>
  );
};
export default RatingFilters;
