import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Link,
} from '@chakra-ui/react';
import { NotAllowedIcon } from '@chakra-ui/icons';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useAuth } from '../../hooks/useAuth';

const NotAllowed = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [previousPath] = useLocalStorage('previousPath', '/intel/projects');

  return (
    <Box m='100px'>
      <Heading fontSize='36px' mb='10px'>
        <NotAllowedIcon /> Az oldal nem jeleníthető meg.
      </Heading>
      <Alert status='error'>
        <AlertIcon />
        <AlertTitle>
          Nem megjeleníthető tartalom&nbsp;
          <Link as={NavLink} to={previousPath}>
            {previousPath}
          </Link>
        </AlertTitle>
        <AlertDescription>Az olal megjelenítéséhez nincs megfelelő jogosultsága.</AlertDescription>
      </Alert>
      <Flex mt={2} flexDirection='column'>
        <Button
          width='fit-content'
          onClick={() => {
            logout();
            navigate('/login');
          }}
        >
          Logout
        </Button>
        <Link as={NavLink} to='/intel/creatives/list'>
          <Button>Creatives</Button>
        </Link>
      </Flex>
    </Box>
  );
};

export default NotAllowed;
