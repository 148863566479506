import React from 'react';

import MenuLayout from '../../../layouts/MenuLayout';
import RoleGuard from '../../../layouts/RoleGuard';

import { adminRoles } from '../../../variables/roles';
import CreativeFinances from './CreativeFinances';

export default function CreativeFinancesPage() {
  return (
    <RoleGuard page roles={[adminRoles.RolePhotonAdmin.key, adminRoles.RolePhotonSuperAdmin.key]}>
      <MenuLayout page={<CreativeFinances />} />
    </RoleGuard>
  );
}
