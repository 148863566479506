export const columnsDataProjects = [
  {
    Header: 'Project name',
    accessor: 'projectName',
  },
  {
    Header: 'Client',
    accessor: 'clientObj',
  },
  {
    Header: 'Time of rating',
    accessor: 'dateOfRating',
  },
  {
    Header: 'Photographer',
    accessor: 'photographerObj',
  },
  {
    Header: 'Editor',
    accessor: 'editorObj',
  },
  {
    Header: 'Rating',
    accessor: 'photographerReview.rating',
  },
  {
    Header: 'Rating message',
    accessor: 'photographerReview.message',
  },
  {
    Header: ' ',
    accessor: 'action',
  },
];

export const columnsDataCheck = [
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'PROGRESS',
    accessor: 'progress',
  },
  {
    Header: 'QUANTITY',
    accessor: 'quantity',
  },
  {
    Header: 'DATE',
    accessor: 'date',
  },
];
export const columnsDataComplex = [
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
  },
  {
    Header: 'DATE',
    accessor: 'date',
  },
  {
    Header: 'PROGRESS',
    accessor: 'progress',
  },
];
