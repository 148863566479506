import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, IconButton, Flex, Text, useToast } from '@chakra-ui/react';
import { AiOutlineDelete } from 'react-icons/ai';

import DevelopmentTable from '../../components/DevelopmentTable';
import { adminRoles } from '../../variables/roles';
import RoleGuard from '../../layouts/RoleGuard';
import { Cell } from 'react-table';
import { useAuth } from '../../hooks/useAuth';
import { useDeleteRecruitment, useGetRecruitments } from 'api/recruitment';
import { getCountryName } from 'utils/country-codes';
import { decodeStringParam } from 'utils/decode-url-params';
import PhotoroomTemplateFilters, { PhotoroomTemplateFilterTypes } from './PhotoroomTemplateFilters';
import { useGetTemplates } from 'api/photoroom-templates';
import CreateTemplateModal, { defaultForm } from './CreateTemplateModal';
import TemplateListMenu from './TemplateListMenu';
import CreateOrUpdateTemplateModal from './CreateTemplateModal';
import DeleteTemplateModal from './DeleteTemplateModal';

export default function PhotoroomTemplates() {
  const queryParams = new URLSearchParams(location.search);
  const {
    name = '',
    client = '',
    version = '',
    templateId = '',
    status = '',
  } = Object.fromEntries(queryParams.entries());

  const [
    { data: templatesData, loading: templatesLoading, error: getTemplatesLoading },
    getTemplateList,
  ] = useGetTemplates();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [formData, setFormData] = useState(defaultForm);
  const [deleteData, setDeleteData] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [filters, setFilters] = useState<PhotoroomTemplateFilterTypes>({
    name,
    client,
    version,
    templateId,
    status,
  });
  const toast = useToast();

  const callGetTemplateList = async (updatedFilters?: PhotoroomTemplateFilterTypes) => {
    getTemplateList({ params: { filters: updatedFilters || filters } });
  };

  useEffect(() => {
    callGetTemplateList(filters);
  }, []);

  const openEditModal = (id: string) => {
    const data = Object.assign(
      {},
      templatesData.find((r: any) => r._id === id),
    );

    setFormData({...data, version: data.version || 'v1'});
    setIsEditModalOpen(true);
  };

  const openDeleteModal = (id: string) => {
    setDeleteData(id);
    setIsDeleteModalOpen(true);
  };

  const columnsDataRecruitment = {
    _id: {
      columnDef: {
        Header: 'ID',
        accessor: '_id',
      },
    },
    name: {
      columnDef: {
        Header: 'Name',
        accessor: 'name',
      },
    },
    templateId: {
      columnDef: {
        Header: 'Template ID',
        accessor: 'templateId',
      },
    },
    version: {
      columnDef: {
        Header: 'Version',
        accessor: 'version',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text fontWeight='bold' color='black'>
            {cell.value ?? 'v1'}
          </Text>
        );
      },
    },
    status: {
      columnDef: {
        Header: 'Status',
        accessor: 'status',
      },
    },
    actions: {
      columnDef: {
        Header: 'Actions',
        accessor: 'actions',
        width: 250,
      },
      renderer: (cell: Cell<any, any>) => {
        const id = cell.row.original._id;
        return (
          <RoleGuard roles={[adminRoles.RolePhotonAdmin.key, adminRoles.RolePhotonSuperAdmin.key]}>
            <Flex gap={10} justifyContent='flex-start' width={200}>
              <Button variant='photonDark' onClick={() => openEditModal(id)}>
                Edit
              </Button>
              <IconButton
                onClick={() => openDeleteModal(id)}
                colorScheme='photonOffWhite'
                color='photonDeepBlue'
                aria-label='Delete recruitment'
                icon={<AiOutlineDelete size={20} />}
              />
            </Flex>
          </RoleGuard>
        );
      },
    },
  };

  return (
    <Box pt={{ base: '30px', md: '80px', xl: '80px' }}>
        <>
          <DevelopmentTable
            variant='underlineRows'
            menuComp={<TemplateListMenu onRefresh={callGetTemplateList} />}
            label={`Photoroom templates`}
            columnsData={Object.values(columnsDataRecruitment).map(
              (column: any) => column.columnDef,
            )}
            renderersData={columnsDataRecruitment}
            tableData={templatesData}
            total={templatesData?.length}
            filterComp={
              <PhotoroomTemplateFilters
                filters={filters}
                setFilters={setFilters}
                handleSearch={callGetTemplateList}
              />
            }
            isLoading={templatesLoading}
          />
        </>

      <CreateOrUpdateTemplateModal
        selectedTemplate={formData}
        isOpen={isEditModalOpen}
        onClose={(successful?: boolean) => {
          setIsEditModalOpen(false);
          if (successful) {
            callGetTemplateList();
          }
        }}
      />

      {deleteData && (
        <DeleteTemplateModal
          deleteData={deleteData}
          isOpen={isDeleteModalOpen}
          onClose={(successful?: boolean) => {
            setIsDeleteModalOpen(false);
            setDeleteData('');
            if (successful) {
              callGetTemplateList();
            }
          }}
        />
      )}
    </Box>
  );
}
