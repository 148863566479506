import React from 'react';
import ActiveCreativeList from './ActiveCreativeList';
import { adminRoles } from '../../variables/roles';
import RoleGuard from '../../layouts/RoleGuard';
import MenuLayout from '../../layouts/MenuLayout';

export default function ActiveCreativesPage() {
  return (
    <RoleGuard
      page
      roles={[
        adminRoles.RolePhotonManager.key,
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]}
    >
      <MenuLayout page={<ActiveCreativeList />} />
    </RoleGuard>
  );
}
