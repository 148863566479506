import {
  createContext,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';


const FormContext = createContext(null);
const confirmMessage = 'You have unsaved changes! Are you sure you want to leave?';

export const FormProvider = ({ children }: { children: ReactElement }) => {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);

  const handleTabChange = (index: number, setSelectedTabIndex: Function) => {
    if (isFormDirty) {
      const userConfirmed = window.confirm(confirmMessage);
      if (userConfirmed) {
        setSelectedTabIndex(index);
        setIsFormDirty(false);
        setShouldReset(true);
      }
    } else {
      setSelectedTabIndex(index);
    }
  };

  const value = useMemo(
    () => ({
      isFormDirty,
      setIsFormDirty,
      shouldReset,
      setShouldReset,
      handleTabChange,
    }),
    [isFormDirty],
  );

  return <FormContext.Provider value={value}>
    {children}
  </FormContext.Provider>;
};
export const useFormContext = () => useContext(FormContext);
