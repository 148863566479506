import useAxios from './basic/use-axios';
import {UseAxiosResult} from 'axios-hooks';
import {AxiosResponse} from 'axios';


export type CreateTemplate = {
  name: string;
  templateId: string;
  status: string;
  version: string;
};

export type UpdateTemplate = CreateTemplate & {
  _id: string;
};

export type ServerResponse = {
  message: string;
};

export type DeleteTemplate = {
  _id: string;
};

export const useCreateTemplate = (): UseAxiosResult<AxiosResponse, CreateTemplate, ServerResponse> => useAxios(
  {
    url: '/admin/photoroom-template',
    method: 'POST',
  },
  {manual: true},
);

export const useUpdateTemplate = (regId: string): UseAxiosResult<AxiosResponse, UpdateTemplate, ServerResponse> => useAxios(
  {
    url: `/admin/photoroom-template/${regId}`,
    method: 'PUT',
  },
  {manual: true},
);

export const useDeleteTemplate = (regId: string): UseAxiosResult<AxiosResponse, DeleteTemplate, ServerResponse> => useAxios(
  {
    url: `/admin/photoroom-template/${regId}`,
    method: 'DELETE',
  },
  {manual: true},
);

export const useGetTemplates = () => useAxios(
  {
    url: '/admin/photoroom-template/list',
    method: 'GET',
  },
);
