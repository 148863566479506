import React, { useState } from 'react';
import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Link,
  Button,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { getCountryName } from 'utils/country-codes';
import { useGetClient } from 'api/clients';
import ClientBasicData from './components/ClientBasicData';
import ClientBillingData from './components/ClientBillingData';
import ClientBranding from './components/ClientBranding';

export default function Client() {
  const { clientId } = useParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [{ data: clientData, loading: getClientLoading, error: getClientError }] =
    useGetClient(clientId);

  const handleTabChange = (index: number) => {
    setSelectedTabIndex(index);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {clientData && (
        <>
            <Flex justifyContent='space-between' alignItems='center'>
              <Flex
              bg='white'
              alignItems='center'
              w={{ base: '90vw', md: '500px' }}
              borderRadius='20px'
              m='10px'
            >
              <Box m='10px 20px' w='80%'>
                <Text fontSize='25px' fontWeight='bold'>
                  {clientData.name}
                </Text>
                <Text>{clientData.email}</Text>
                <Text>{clientData.phone}</Text>
                <Flex justifyContent='space-between' w='90%' alignItems='center'>
                  <Text>{getCountryName(clientData.country)}</Text>
                </Flex>
              </Box>
            </Flex>
            <Link href={clientData.storage?.oauth?.url} _hover={{ textDecoration: 'none' }}>
              <Button as="a" variant='photonDark'>Client drive</Button>
            </Link>
            </Flex>
          <Tabs index={selectedTabIndex} onChange={handleTabChange}>
            <TabList ml='0px' mr='20px'>
              <Tab w={{ base: '70px', md: 'auto' }}>Basic data</Tab>
              <Tab w={{ base: '70px', md: 'auto' }}>Billing</Tab>
              <Tab w={{ base: '70px', md: 'auto' }}>Branding</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ClientBasicData clientData={clientData} />
              </TabPanel>

              <TabPanel>
                <ClientBillingData clientData={clientData} />
              </TabPanel>

              <TabPanel>
                <ClientBranding clientData={clientData} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </Box>
  );
}
