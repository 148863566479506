import { useCallback, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { columnsDataProjects } from '../../dashboard/variables/columnsData';
import { useGetFinishedUnderFiveStarRatingProjects } from '../../../api/projects';
import RatingTable from '../components/RatingTable';
import { ProjectRatingFilters } from '../../dashboard/types';
import React from 'react';

export default function ClosedRatings(props: {
  selectedTab: number;
  filters: ProjectRatingFilters;
  page: number;
  perPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  setFilters: React.Dispatch<React.SetStateAction<ProjectRatingFilters>>;
}) {
  const { selectedTab, page, perPage, setPage, setPerPage, setFilters, filters } = props;

  const [
    { data: projectsData, loading: getProjectsLoading, error: getProjectsError },
    getProjects,
  ] = useGetFinishedUnderFiveStarRatingProjects();

  const callGetProjects = useCallback(
    async (page: number, perPage: number, filters: ProjectRatingFilters) => {
      let result;
      try {
        result = await getProjects({
          params: {
            page,
            perPage,
            filters,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getProjects],
  );

  useEffect(() => {
    callGetProjects(page, perPage, filters);
  }, [page, perPage, selectedTab]);

  return (
    <Box bg='white' borderRadius='20px'>
      <RatingTable
        columnsData={columnsDataProjects}
        tableData={projectsData?.data}
        total={projectsData?.total}
        page={page}
        perPage={perPage}
        setPerPage={setPerPage}
        setPage={setPage}
        callBack={callGetProjects}
        setFilters={setFilters}
        filters={filters}
      />
    </Box>
  );
}
