import React, {useEffect, useState} from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast
} from "@chakra-ui/react";

import {useDeleteAdminUsers} from "../../api/adminUsers";


export default function DeleteAdminUserModal(
  {
    userData,
    isOpen,
    onClose,
  }: {
    isOpen: boolean;
    onClose: (successfulDelete?: boolean) => void;
    userData: {name: string, id: string};
  }
) {
  const toast = useToast();

  const [isConfirmValid, setConfirmValid] = useState(undefined);
  const [confirmName, setConfirmName] = useState('');

  const [
    {
      loading: isDeleteInProgress,
      error
    },
    deleteAdminUser,
  ] = useDeleteAdminUsers(userData.id);

  useEffect(
    () => {
      if (error) {
        toast(
          {
            title: 'Delete is unsuccessful!',
            status: 'error',
            duration: 3000,
          }
        );
      }
    },
    [error],
  );

  return <Modal
    isOpen={isOpen}
    onClose={
      () => {
        setConfirmValid(undefined);
        setConfirmName('');
        onClose();
      }
    }
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Delete User</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl id="confirmName">
          <FormLabel>Confirm by name '{userData.name}'</FormLabel>
          <Input
            onChange={
              (e) => {
                setConfirmValid(undefined);
                setConfirmName(e.target.value)
              }
            }
          />
        </FormControl>
        {
          isConfirmValid === false
          && <Alert status='error' my={2}>
            <AlertIcon />
            <AlertTitle mr={2}>
              `Name not matched ${userData.name}`
            </AlertTitle>
            <AlertDescription>
              Change confirm name field to match with the one you want to delete
            </AlertDescription>
          </Alert>
        }
      </ModalBody>
      <ModalFooter>
        <Button
          colorScheme="red"
          mr={3}
          onClick={
            async () => {
              setConfirmValid(confirmName === userData.name);
              if (confirmName !== userData.name) {
                return;
              }

              try {
                await deleteAdminUser();
                setConfirmValid(undefined);
                setConfirmName('');
                onClose(true);
              } catch (e) {
                // handled
              }
            }
          }
          isLoading={isDeleteInProgress}
        >
          Delete
        </Button>
        <Button
          variant="ghost"
          onClick={
            () => {
              setConfirmValid(undefined);
              setConfirmName('');
              onClose();
            }
          }
        >
          Cancel
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>;
}
