import { useEffect } from 'react';

import {
  Box,
  Spinner,
  useToast,
  Heading,
} from '@chakra-ui/react';

import ProfileSettings, {AdminUser} from './components/profileSettings';
import { useGetUser } from 'api/user';

export default function ProfileSettingsLoader() {
  const toast = useToast();
  const [
    {
      data: userDoc,
      loading: getUserInProgress,
      error: getUserError,
    }
  ] = useGetUser();

  useEffect(
    () => {
      if (getUserError) {
        toast({
          title: 'Error fetching data, we are working on it',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [getUserError]
  );

  return <Box mt='100px'>
    <Heading variant='simpleHeading'>Profile</Heading>
    <Box m='50px 20px'>
      {
        getUserInProgress
        && <Spinner />
      }
      {
        !getUserInProgress
        && userDoc
        && <ProfileSettings user={userDoc as AdminUser} />
      }`
    </Box>
  </Box>;
}
