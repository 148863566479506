import useAxios from './basic/use-axios';

export const useGetActiveCreatives = () =>
  useAxios(
    {
      url: '/admin/creative/list/active',
      method: 'GET',
    },
    { manual: true },
  );

export const useGetInactiveCreatives = () =>
  useAxios(
    {
      url: '/admin/creative/list/inactive',
      method: 'GET',
    },
    { manual: true },
  );

export const useGetNewApplicantCreatives = () =>
  useAxios(
    {
      url: '/admin/creative/list/new-applicant',
      method: 'GET',
    },
    { manual: true },
  );

export const useGetCreative = (creativeId: string) =>
  useAxios({ url: `admin/creative/${creativeId}` });

export const useUpdateCreative = (creativeId: string) =>
  useAxios(
    {
      url: `admin/creative/${creativeId}`,
      method: 'PATCH',
    },
    { manual: true },
  );

export const useGetCreativeRatingData = (creativeId: string) =>
  useAxios(
    {
      url: `admin/creative/${creativeId}/rated-projects`,
      method: 'GET',
    },
    { manual: true },
  );

export const useDeactivateCreative = (creativeId: string) =>
  useAxios(
    {
      url: `admin/creative/${creativeId}/deactivate`,
      method: 'POST',
    },
    { manual: true },
  );

export const useActivateCreative = (creativeId: string) =>
  useAxios(
    {
      url: `admin/creative/${creativeId}/activate`,
      method: 'POST',
    },
    { manual: true },
  );

export const useDeleteCreative = (creativeId: string) =>
  useAxios(
    {
      url: `admin/creative/${creativeId}/delete`,
      method: 'POST',
    },
    { manual: true },
  );

export const useManageCreative = () =>
  useAxios(
    {
      url: `admin/creative/manage`,
      method: 'POST',
    },
    { manual: true },
  );

export const useGetCreativeCountries = () =>
  useAxios({
    url: `admin/creative/countries`,
    method: 'GET',
  });

export const useGetAllCreativeCerts = () =>
  useAxios(
    {
      url: '/admin/creative/all-certs',
      method: 'GET',
    },
    { manual: true },
  );

export const useGetCreativeCerts = () =>
  useAxios(
    {
      url: '/admin/creative/certs',
      method: 'GET',
    },
    { manual: true },
  );

export const useGetAllCreativeCertCSV = () =>
  useAxios(
    {
      url: '/admin/creative/all-certs/csv',
      method: 'GET',
    },
    { manual: true },
  );

export const useGetCreativePackages = (creativeId: string) =>
  useAxios({
    url: `/admin/creative/${creativeId}/packages`,
    method: 'GET',
  });

export const useGetCreativesCSV = () =>
  useAxios(
    {
      url: '/admin/creative/list/csv',
      method: 'GET',
    },
    { manual: true },
  );

export const useGetCreativeFeeDocs = () =>
  useAxios(
    {
      url: '/admin/creative/list/fee-docs',
      method: 'GET',
    },
    { manual: true },
  );

export const useGetAllCreativeFeeDocsCsv = () =>
  useAxios(
    {
      url: '/admin/creative/export/fee-docs',
      method: 'GET',
    },
    { manual: true },
  );

export const useGetAllCreativeFeeDocs = () =>
  useAxios(
    {
      url: '/admin/creative/all-fee-docs',
      method: 'GET',
    },
    { manual: true },
  );

export const useSendAdminComment = (creativeId: string) =>
  useAxios(
    {
      url: `/admin/creative/${creativeId}/comment`,
      method: 'POST',
    },
    { manual: true },
  );

export const useMoveToWaitingForBriefing = (creativeId: string) =>
  useAxios(
    {
      url: `/admin/creative/${creativeId}/waiting-for-briefing`,
      method: 'POST',
    },
    { manual: true },
  );
  
export const useGetAllCreativeInvoiceZip = () =>
  useAxios(
    {
      url: '/admin/creative/invoices-zip',
      method: 'GET',
    },
    { manual: true },
  );

export const useGetCreativeInvoices = () =>
  useAxios(
    {
      url: '/admin/creative/invoices',
      method: 'GET',
    },
    { manual: true },
  );
