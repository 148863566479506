import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  useUpdateProjectPrices,
} from 'api/projects';
import {useCallback, useEffect, useState} from 'react';
import {MdModeEdit} from 'react-icons/md';
import {CustomFieldStyle} from '../variables/styles';
import {NavLink} from "react-router-dom";
import {cancelEvent} from './ProjectEventsAndDocuments';
import {ProjectPrice, ProjectPriceType,} from '../constants';
import { ProjectEventType } from '../types';

export default function CreativeFeeEditorField(props: {
  label: string;
  type: ProjectPriceType;
  projectData: any;
  refetch: Function;
}) {
  const {
    label,
    type,
    projectData,
    refetch,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [editedPrice, setEditedPrice] = useState<number>(0);

  const [changeReason, setChangeReason] = useState('');
  const [eventId, setEventId] = useState('');

  const toast = useToast();

  const [{error: updateProjectPricesError}, updateProjectPrices] = useUpdateProjectPrices(projectData._id);

  const handleEdit = (eventId?: string) => {
    setIsEditing(true);
    setEditedPrice(projectData[type]);
    setChangeReason('');
    if (eventId) {
      setEventId(eventId);
    }
  };

  useEffect(() => {
    if (updateProjectPricesError) {
      toast({
        title: 'Error during price correction',
        status: 'error',
        duration: 3000,
      });
    }
  }, [updateProjectPricesError]);


  const handleSavePrice = async (eventId?:  string) => {
    const currentPrice = projectData.events.find((e: ProjectEventType)=> e._id === eventId)?.details?.creativeFee || 0

    if (currentPrice === editedPrice ) {
      toast({
        title: "Updated price can't be the same as the current.",
        status: 'error',
        duration: 3000,
      });
      return;
    }

    if (!changeReason) {
      toast({
        title: 'Price correction reason is mandatory.',
        status: 'error',
        duration: 3000,
      });
      return;
    }

    const data = {
      updatedPrice: editedPrice,
      changeReason: changeReason,
      type,
      eventId,
    };

    try {
      await updateProjectPrices({data});
      toast({
        title: `${type}: ${currentPrice} --> ${editedPrice}`,
        status: 'success',
        duration: 3000,
      });
      refetch();
      setIsEditing(false);
    } catch {
    }
  };

  const handleDeleteCreativeFee = async (eventId: string) => {
    const data = {
      type: ProjectPrice.CREATIVE_FEE,
      eventId,
    };

    try {
      await updateProjectPrices({data});
      toast({
        title: `Creative fee successfully deleted`,
        status: 'success',
        duration: 3000,
      });

      refetch();
      setIsEditing(false);
    } catch {}
  };

  const creativeFeeEventType = type === ProjectPrice.CREATIVE_FEE ? cancelEvent.cancelByCreative : type;


  const onPriceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      let newPrice = parseFloat(e.target.value);

      if (isNaN(newPrice)) {
        newPrice = undefined;
      }

      setEditedPrice(newPrice);
    },
    []
  );

  return (
    <FormControl>
      <FormLabel display='flex' justifyContent={'space-between'}>
        {label}
      </FormLabel>
     <Flex direction='column'>
            {projectData.events
              .filter((e: any) => e.event === creativeFeeEventType)
              .map((e: any, i: number) => {
                return (
                  <Flex key={i} w='100%' direction='column'>
                    <Link as={NavLink} to={`/intel/creative/${e.details.creativeId}`}>
                      {e.details.creativeName}
                    </Link>
                    {isEditing && eventId === e._id ?
                      <Flex direction='column' w={'100%'}>
                        <Input
                          placeholder='Reason of price correction'
                          bg='white'
                          value={changeReason}
                          mb={4}
                          onChange={(e) => setChangeReason(e.target.value)}
                        />
                        <Flex>
                          <Input
                            w='100%'
                            bg='white'
                            defaultValue={e.details.creativeFee}
                            value={editedPrice}
                            mb={4}
                            onChange={onPriceChange}
                          />
                          <Button
                            variant='photonMedium'
                            ml='5px'
                            onClick={() => setIsEditing(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant='photonLight'
                            m='0px 5px'
                            onClick={() => handleSavePrice(eventId)}
                          >
                            Send
                          </Button>
                          <Button
                            variant='photonDark'
                            onClick={() => handleDeleteCreativeFee(e._id)}
                          >
                            Delete
                          </Button>
                        </Flex>
                      </Flex>
                      :
                      <Flex w='100%'>
                        <Flex {...CustomFieldStyle} w='350px'>
                          <Text>{e.details.creativeFee}</Text>
                        </Flex>
                        <Button ml='5px' onClick={() => handleEdit(e._id)}>
                          <MdModeEdit  size={20}/>
                        </Button>
                      </Flex>
                    }
                  </Flex>
                )
              })}

        </Flex>
    </FormControl>
  );
}
