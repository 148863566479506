import { useState } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from '@chakra-ui/react';
import {DATE_CHANGE_REASON} from '../constants';

export default function TimeChangeReasonModal({
                                                handleSave,
                                                isOpen,
                                                onClose,
                                                data,
                                              }: {
  handleSave: Function;
  isOpen: boolean;
  onClose: (successfulDelete?: boolean) => void;
  data: any;
}) {
  const [dateChangeReason, setDateChangeReason] = useState('');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>
          <Text mx='20px' align='center'>Please specify who is responsible for the date change!</Text>
        </ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Flex justifyContent='center'>
            <Select
              placeholder='Select reason'
              value={dateChangeReason}
              bg='white'
              onChange={(e) => setDateChangeReason(e.target.value)}
              w='300px'
              m='5px'
            >
              {Object.values(DATE_CHANGE_REASON)?.map((reason: { value: string; label: string }) => {
                return (
                  <option value={reason.value} key={reason.value}>
                    {reason.label}
                  </option>
                );
              })}
            </Select>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex w='100%' justifyContent='center'>
            <Button mr='5px' w='140px' variant='photonMedium' onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              w='140px'
              variant='photonDark'
              onClick={() => {
                dateChangeReason && handleSave({...data, dateChangeReason})
                onClose()
              }}
              isDisabled={!dateChangeReason}
            >
              Confirm
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
