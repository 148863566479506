import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useManageCreative } from 'api/staff-api';
import { ApplicantStatus } from '../constants';

export default function HiringModal({
  creativeId,
  judgement,
  isOpen,
  onClose,
  refetch,
  status
}: {
  isOpen: boolean;
  onClose: (successfulDelete?: boolean) => void;
  judgement: string;
  creativeId: string;
  refetch: Function;
  status?: string
}) {
  const toast = useToast();

  const [isConfirmValid, setConfirmValid] = useState(undefined);
  const [confirmName, setConfirmName] = useState('');

  const [{ error: manageCreativeError, loading: manageCreativeLoading }, manageCreative] =
    useManageCreative();

  const handleManageCreative = async () => {
    try {
      const res = await manageCreative({
        data: {
          judgement,
        },
        params: {
          creativeId,
        },
      });
      toast({
        title: res.data.message,
        status: 'success',
        duration: 3000,
      });
      refetch();
      onClose();
    } catch {
      toast({
        title: 'Error during updating hiring status',
        status: 'error',
        duration: 3000,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setConfirmValid(undefined);
        setConfirmName('');
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <Text>
          {judgement === 'accepted'
            ? (status === ApplicantStatus.NEW_APPLICANT
                ? `Are you sure you want to move the photographer to Waiting for briefing status?`
                : `Are you sure you want to accept the photographer's application?`)
            : `Are you sure you want to reject the photographer's application?`}
        </Text>
        </ModalBody>
        <ModalFooter>
          <Button mr='5px' w='140px' variant='photonMedium' onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            w='140px'
            variant='photonDark'
            onClick={handleManageCreative}
            isLoading={manageCreativeLoading}
          >
            Yes, proceed
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
