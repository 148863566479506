import useAxios, {ServerClientInstance} from './basic/use-axios';

export const getPackages = async (searchText: string) => {
  if (!searchText) {
    return [];
  }

  try {
    const packagesResponse = await ServerClientInstance.get(
      '/admin/package/names',
      {params: {search: searchText}}
    );
    return packagesResponse.data;
  } catch (e) {
    return [];
  }
}

export const useGetCreativesOfPackage = (packageId: string) =>
  useAxios(
    {
      url: `/admin/package/${packageId}/creatives/`,
      method: 'GET',
    },
    { manual: true },
  );

export const useRemoveCreativeFromPackage = () =>
  useAxios(
    {
      url: '/admin/package/remove-creative',
      method: 'POST',
    },
    { manual: true },
  );
export const useGetEditorsOfPackage = (packageId: string) =>
  useAxios(
    {
      url: `/admin/package/${packageId}/editors/`,
      method: 'GET',
    },
    { manual: true },
  );

export const useGetPackageById = (packageId: string) =>
  useAxios(
    {
      url: `/admin/package/${packageId}`,
      method: 'GET',
    },
    { manual: true },
  );

export const useGetClientPackages = () =>
  useAxios(
    {
      url: '/admin/package/client',
      method: 'GET',
    },
    { manual: true },
  );

export const useAddCreativeToPackage = () =>
  useAxios(
    {
      url: '/admin/package/add-creative',
      method: 'POST',
    },
    { manual: true },
  );
