import React, { useCallback, useEffect, useState } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useGetClients } from 'api/clients';
import { ClientListFilterTypes } from './types';
import { columnsDataClients } from './variables/columnsData';
import ClientListTable from './components/ClientListTable';

export default function ClientList() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { search = '', country = '', status = '' } = Object.fromEntries(queryParams.entries());

  const [
    { data: clientsData, loading: getClientsLoading, error: getClientsError },
    getClients,
    cancelGetClients,
  ] = useGetClients();

  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sorting, setSorting] = useState({
    field: searchParams.get('sort') || 'name',
    order: parseInt(searchParams.get('sortOrder')) || 1,
  });
  const [filters, setFilters] = useState<ClientListFilterTypes>({
    search,
    country,
    status,
  });

  const callGetClients = useCallback(
    async (
      page: number,
      perPage: number,
      sorting: { field: string; order: number },
      filters: ClientListFilterTypes,
    ) => {
      try {
        await getClients({
          params: {
            page,
            perPage,
            sorting,
            filters,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getClients],
  );

  useEffect(() => {
    callGetClients(page, perPage, sorting, filters);
  }, [page, perPage, sorting]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <ClientListTable
        columnsData={columnsDataClients}
        tableData={clientsData?.data}
        total={clientsData?.total}
        page={page}
        perPage={perPage}
        setPerPage={setPerPage}
        setPage={setPage}
        filters={filters}
        setFilters={setFilters}
        callBack={callGetClients}
        setSorting={setSorting}
        sorting={sorting}
      />
    </Box>
  );
}
