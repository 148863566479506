import React, { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Link,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import { NavLink, useSearchParams } from 'react-router-dom';

import Card from 'components/card/Card';
import moment from 'moment';
import { BiSolidDownArrowAlt, BiSolidUpArrowAlt } from 'react-icons/bi';
import { useManageCreative } from 'api/staff-api';
import { FaRegCheckCircle } from 'react-icons/fa';
import { TiDeleteOutline } from 'react-icons/ti';
import { ClientListFilterTypes } from '../types';
import ClientListFilters from './ClientListFilters';
import { getCountryName } from 'utils/country-codes';

export type Row = {
  original: any;
};

const getTitle = (type: string) => {
  if (type === 'active') {
    return 'Active photographers';
  }
  if (type === 'inactive') {
    return 'Inactive photographers';
  }
  if (type === 'new-applicants') {
    return 'New applicants';
  }
};

export default function ClientListTable(props: {
  columnsData: any;
  tableData: any;
  total: number;
  page: number;
  perPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  callBack: any;
  filters: ClientListFilterTypes;
  setFilters: React.Dispatch<React.SetStateAction<ClientListFilterTypes>>;
  setSorting: React.Dispatch<React.SetStateAction<{ field: string; order: number }>>;
  sorting: { field: string; order: number };
}) {
  const {
    columnsData,
    tableData,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    callBack: callGetCreatives,
    filters,
    setFilters,
    setSorting,
    sorting,
  } = props;

  const toast = useToast();

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData || [], [tableData]);
  const pageSizes = [5, 10, 25, 50, 100];

  const [showFilters, setShowFilters] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const [{ error: manageCreativeError }, manageCreative] = useManageCreative();

  useEffect(() => {
    if (manageCreativeError) {
      toast({
        title: 'Managing the hiring process update error',
        status: 'error',
        duration: 3000,
      });
    }
  }, [manageCreativeError]);

  useEffect(() => {
    setPageSize(perPage);
  }, [perPage]);

  useEffect(() => {
    const queryString = Object.entries(filters)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const encodedValues = value
            .map((v) => encodeURIComponent(v.replace(/,/g, '-')))
            .join(',');
          return `${encodeURIComponent(key)}=${encodedValues}`;
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`;
      })
      .join('&');

    window.history.pushState(null, '', `?${queryString}`);
  }, [filters]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page: pageData,
    prepareRow,
    setPageSize,
    state: { pageSize },
  } = tableInstance;

  const startRow = (page - 1) * perPage + 1;
  const endRow = Math.min(page * perPage, total);
  const actualEndRow = Math.min(endRow, total);

  const handlePageSizeChange = (e: number) => {
    setPage(1);
    setPerPage(e);
  };

  const handleSearch = async (resetFilters: ClientListFilterTypes) => {
    try {
      if (resetFilters) {
        callGetCreatives(page, perPage, sorting, resetFilters);
      } else {
        callGetCreatives(page, perPage, sorting, filters);
      }
    } catch (e) {
      console.log(e);
    }
    setPage(1);
  };

  const handleFilterChange = (value: string | Date, name: keyof ClientListFilterTypes) => {
    const updatedFilters: Partial<ClientListFilterTypes> = { ...filters, [name]: value };
    setFilters(updatedFilters as ClientListFilterTypes);

    if (name !== 'search' || !value) {
      handleSearch(updatedFilters);
    }
  };

  const handleSort = (column: any) => {
    const columnId = column.id;
    const clickedColumn = headerGroups
      .flatMap((group) => group.headers)
      .find((column) => column.id === columnId);

    if (clickedColumn && column.sorting) {
      const isColumnAlreadySorted = sorting.field === columnId;
      const order = isColumnAlreadySorted ? sorting.order * -1 : -1;

      setSearchParams((params) => {
        params.set('sort', columnId);
        params.set('order', order as unknown as string);
        return params;
      });
      setPage(1);
      setSorting({ field: columnId, order: order });
    }
  };

  return (
    <Card flexDirection='column' w='100%' px='0px'>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
          Clients
        </Text>
      </Flex>
      <Flex>
        {showFilters && (
          <ClientListFilters
            handleFilterChange={handleFilterChange}
            filters={filters}
            handleSearch={handleSearch}
            setFilters={setFilters}
          />
        )}
      </Flex>
      <Box overflowX='scroll'>
        <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column: any, index: number) => {
                  return (
                    <Th
                      pe='10px'
                      key={index}
                      borderColor={borderColor}
                      textAlign='center'
                      onClick={() => handleSort(column)}
                    >
                      <Flex
                        justify='space-between'
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color='gray.400'
                        justifyContent='center'
                        alignItems='center'
                      >
                        {column.render('Header')}
                        {column.sorting && (
                          <Flex direction='column'>
                            {sorting.field === column.id && sorting.order > 0 ? (
                              <BiSolidUpArrowAlt size='20px' color='#6B52FFB3' />
                            ) : (
                              <BiSolidUpArrowAlt opacity={0.5} size='20px' />
                            )}
                            {sorting.field === column.id && sorting.order < 0 ? (
                              <BiSolidDownArrowAlt size='20px' color='#6B52FFB3' />
                            ) : (
                              <BiSolidDownArrowAlt opacity={0.5} size='20px' />
                            )}
                          </Flex>
                        )}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {pageData.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    const currentRow: Row = cell.row;
                    let data;
                    if (cell.column.id === 'action') {
                      data = (
                        <Flex align='center'>
                          <Link as={NavLink} to={`/intel/client/${currentRow.original._id}`}>
                            <Button variant='photonLight'>View</Button>
                          </Link>
                        </Flex>
                      );
                    } 
                   else if (cell.column.id === 'country') {
                      data = (
                        <Flex align='center'>
                          <Text fontWeight='bold' color='black'>{getCountryName(cell.value)}</Text>
                        </Flex>
                      );
                    } else {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor='lightgrey'
                        p='10px 20px'
                      >
                        <Flex justifyContent='center'>{data}</Flex>
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>

      <Flex justify='space-between' m='10px' px='10px'>
        <Flex>
          <Select
            value={pageSize}
            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
            variant='photonMedium'
            w='75px'
          >
            {pageSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex>
          <Button onClick={() => setPage(page - 1)} bg='white' isDisabled={!page || page === 1}>
            <GrLinkPrevious />
          </Button>
          <Flex justify='center' mx='10px' mt='6px'>
            <Text color='gray.400'>
              {startRow}-{actualEndRow} of {total}
            </Text>
          </Flex>
          <Button
            onClick={() => setPage(page + 1)}
            bg='white'
            isDisabled={!page || endRow === total}
          >
            <GrLinkNext />
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
